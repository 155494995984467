import React, { Component } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import API from "../../Api";
import Testimonial2 from "../element/testmonial2";
import ServicesSlider from "../element/servicesSlider";
import CounterSection from "../element/counterSection";
import ScrollToTop from "../element/scrollToTop";
import HomeSlider from "../element/homeslider";
import "../../css/plugins.css";
import "../../css/style.css";
import "../../css/skin/skin-1.css";
import "../../css/templete.min.css";

const bg = require("../../images/background/bg11.jpg");
const bg2 = require("../../images/Fotter_Photos.jpg");
const loaderIMG = require("../../images/Loading_icon.gif");

export const homeSliderContent = [
  {
    image: require("../../images/main-slider/4.jpg"),
    title: "Neelkanth Fabricator",
    description: "Incredible Service To Incredible Nation ",
  },
  {
    image: require("../../images/main-slider/03.jpg"),
    title: "Wide range of trailers available in all sizes.",
    description: "We Build customized trailer as per your requirements",
  },
  {
    image: require("../../images/main-slider/3.jpg"),
    title: "We only make promises we can keep.",
    description:
      "\nWe present our capabilities honestly.\nWe only make promises we can keep.\nWe build trust by delivering exactly according to the promises we have made",
  },
  {
    image: require("../../images/main-slider/2.jpg"),
    title: "More Than 9999 Tankers And Trailers on Road",
    description: "",
  },
  {
    image: require("../../images/main-slider/1.jpg"),
    title: "Fast And Reliable",
    description: "Our diligent team of professionals understands your requirements and acts accordingly.",
  },
];

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      data: [],
    };
  }

  componentDidMount() {
    API.get(`products/special`).then((data) => {
      console.log(data);
      this.setState({ data: data });
    });
  }

  openModal = (event) => {
    event.preventDefault();
    this.setState({ isOpen: true });
  };

  closeModal = () => {
    this.setState({ isOpen: false });
  };

  render() {
    return (
      <>
        <HomeSlider
          data={homeSliderContent}
          contentWrapperCls="sliderStyle4"
          btnCls="site-button"
          btnText="ABOUT US"
          btn2Text="OUR SERVICES"
          show2Btn
        />

        <div className="page-content bg-white">
          <div className="content-block">
            <div className="section-full bg-white content-inner-2" id="about-us" style={{ backgroundImage: "url(" + bg + ")" }}>
              <div className="container">
                <div className="section-head text-black text-center">
                  <h2 className="title">Integrity</h2>
                  <p>
                    We present our capabilities honestly. We only make promises we can keep. We build trust by delivering exactly according
                    to the promises we have made.
                  </p>
                </div>
                <ServicesSlider />
              </div>
            </div>

            <div className="section-full content-inner bg-gray">
              <div className="container">
                <div className="section-head text-black text-center">
                  <h2 className="title">Primary product</h2>
                </div>
                <div className="row">
                  {this.state.data.length === 0 ? (
                    <img src={loaderIMG} alt="" className="LoaderCSS" />
                  ) : (
                    this.state.data.map((product) => (
                      <div className="col-lg-4 col-md-6 col-sm-12 m-b50 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.3s">
                        <Link to={`/product/${product.product_id}`}>
                          <div className="dlab-box service-box-3">
                            <Link to={`/product/${product.product_id}`}>
                              <div className="dlab-media radius-sm dlab-img-overlay1">
                                <Link to={`/product/${product.product_id}`}>
                                  <img height="417" width="298" src={product.images[0].url} alt="" />
                                </Link>
                              </div>
                            </Link>
                            <div className="dlab-info">
                              <h4 className="title">
                                <Link to={`/product/${product.product_id}`}>{product.name}</Link>
                              </h4>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>

            <div className="section-full">
              <div className="row m-lr0">
                <div
                  className="col-xl-6 col-lg-12 p-lr0 d-flex dis-tbl latest-project-info bg-primary wow fadeInLeft"
                  data-wow-duration="2s"
                  data-wow-delay="0.3s"
                >
                  <div className="text-white align-self-center ">
                    <div className="our-story">
                      <span
                        style={{
                          color: "#f7f9fb !important",
                        }}
                      >
                        OUR STORY
                      </span>
                      <h2 className="title">
                        Running a <br />
                        successful business <br />
                        <span
                          style={{
                            color: "#f7f9fb !important",
                          }}
                        >
                          since 1992
                        </span>
                      </h2>
                      <h4>
                        Neelkanth Fabricator was established in the year 1992, with an aim of providing the best Tankers and Trailers to
                        our customers. Since its inception, it has been providing an extensive assortment of tankers and trailers, committed
                        to satisfying the needs of the customers both locally and internationally.{" "}
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-12 p-lr0 wow fadeInRight" data-wow-duration="2s" data-wow-delay="0.3s">
                  <div className="dlab-box overlay-gallery-bx1 lightgallery">
                    <div className="dlab-thum dlab-img-overlay1 primary">
                      <Link to="#">
                        {" "}
                        <img className="img-cover" src={require("../../images/Home_Page_Our_Story.jpg")} alt="" />{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ModalVideo channel="youtube" allowFullScreen isOpen={this.state.isOpen} videoId="_FRZVScwggM" onClose={this.closeModal} />

            {/* <div className="section-full bg-gray content-inner">
              <div className="container">
                <div className="section-head text-black text-center">
                  <h2 className="title">Our Team</h2>
                </div>
                <div className="row text-center">
                  <div className="col-lg-3 col-md-6 col-sm-6 wow bounceInLeft" data-wow-duration="2s" data-wow-delay="0.3s">
                    <div className="dlab-box m-b30 dlab-team9">
                      <div className="dlab-media dlab-media-right">
                        <Link to="/team-1">
                          <img alt="" src={require("../../images/our-team/pic4.jpg")} />
                        </Link>
                        <div className="clearfix">
                          <h4 className="dlab-title">
                            <Link to="/team-1">Late. Jeram N. Sorathiya</Link>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 wow bounceInDown" data-wow-duration="2s" data-wow-delay="0.3s">
                    <div className="dlab-box m-b30 dlab-team9">
                      <div className="dlab-media dlab-media-right">
                        <Link to="/team-1">
                          <img alt="" src={require("../../images/our-team/pic2.jpg")} />
                        </Link>
                        <div className="clearfix">
                          <h4 className="dlab-title">
                            <Link to="/team-1">Kirit M. Sorathiya</Link>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 wow bounceInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                    <div className="dlab-box m-b30 dlab-team9">
                      <div className="dlab-media dlab-media-right">
                        <Link to="/team-1">
                          <img alt="" src={require("../../images/our-team/pic3.jpg")} />
                        </Link>
                        <div className="clearfix">
                          <h4 className="dlab-title">
                            <Link to="/team-1">Mavji N. Sorathiya</Link>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 wow bounceInRight" data-wow-duration="2s" data-wow-delay="0.3s">
                    <div className="dlab-box m-b30 dlab-team9">
                      <div className="dlab-media dlab-media-right">
                        <Link to="/team-1">
                          <img alt="" src={require("../../images/our-team/pic1.jpg")} />
                        </Link>
                        <div className="clearfix">
                          <h4 className="dlab-title">
                            <Link to="/team-1">Chirag J. Sorathiya</Link>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <CounterSection backgroundImage={bg2} />

            <div className="section-full bg-white content-inner-2" style={{ backgroundImage: "url(" + bg + ")" }}>
              <div className="container">
                <div className="section-head text-black text-center">
                  <h2 className="title">Clients Review</h2>
                </div>
                <Testimonial2 />
              </div>
            </div>
            <div className="section-full call-action bg-primary wow fadeIn" data-wow-duration="2s" data-wow-delay="0.3s">
              <div className="container">
                <div className="row">
                  <div className="col-lg-9 text-white">
                    <h2 className="title">Contact Us Now</h2>
                    <p className="m-b0">Our diligent team of professionals understands your requirements and acts accordingly.</p>
                  </div>
                  <div className="col-lg-3 d-flex">
                    <Link to="/contact-us" className="site-button btnhover13 white align-self-center outline ml-auto outline-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ScrollToTop className="style1 radius" />
      </>
    );
  }
}
export default Index;
