import React, { Component } from "react";
import { Link } from "react-router-dom";
import API from "../../Api";

class ForgotPassword extends Component {
  componentDidMount() {
    if (localStorage.getItem("name")) {
      this.props.history.push(`/`);
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      errorMessage: "",
      is_otp_send: false,
      otp_input: "",
      otp_error: "",
      reset_password_screen: false,
      password: "",
      conform_password: "",
    };
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onSubmitEmail = async (e) => {
    e.preventDefault();
    const { email } = this.state;
    API.Auth("user/forgot-password/1", { email: email })
      .then((data) => {
        this.setState({ is_otp_send: true });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ errorMessage: err.response.data.error.message });
      });
  };
  onSubmitOTP = async (e) => {
    e.preventDefault();
    const { otp_input, email } = this.state;
    API.Auth("user/forgot-password/2", { otp: otp_input, email: email })
      .then((data) => {
        console.log(data);
        this.setState({ reset_password_screen: true });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ otp_error: true });
      });
  };

  onSubmitPassword = async (e) => {
    e.preventDefault();
    const { otp_input, email, password, conform_password } = this.state;
    API.Auth("user/forgot-password/3", { otp: otp_input, email: email, password: password, conform_password: conform_password })
      .then((data) => {
        this.props.history.push("/login");
      })
      .catch((err) => {
        console.log(err);
        this.setState({ otp_error: true });
      });
  };

  render() {
    const { email, is_otp_send, otp_input, otp_error, reset_password_screen, password, conform_password } = this.state;
    return (
      <>
        <div className="page-content bg-white">
          <div className="section-full content-inner shop-account">
            <div className="container">
              <div className="row dzseth">
                <div className="col-lg-4 col-md-4 m-b30"></div>
                <div className="col-lg-4 col-md-4 m-b30">
                  <div className="p-a30 border-1 seth">
                    {reset_password_screen ? (
                      <div className="tab-content nav">
                        <form id="login" className="tab-pane active col-12 p-a0 " onSubmit={this.onSubmitPassword}>
                          <h4 className="font-weight-700">Reset Password</h4>
                          <p className="font-weight-600">Please enter your new password below.</p>
                          <div className="form-group">
                            <label className="font-weight-700">Password *</label>
                            <input
                              name="password"
                              required
                              className="form-control"
                              type="password"
                              value={password}
                              onChange={this.onChange}
                            />
                          </div>
                          <div className="form-group">
                            <label className="font-weight-700">Confirm Password *</label>
                            <input
                              name="conform_password"
                              required
                              className="form-control"
                              type="password"
                              value={conform_password}
                              onChange={this.onChange}
                            />
                          </div>
                          <div className="form-group">{otp_error ? <span className="error"> Please check password </span> : null}</div>
                          <br />
                          <div className="text-left">
                            <button type="submit" className="site-button m-r5 button-lg radius-no">
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    ) : (
                      <div className="tab-content nav">
                        {is_otp_send ? null : (
                          <form id="login" className="tab-pane active col-12 p-a0 " onSubmit={this.onSubmitEmail}>
                            <h4 className="font-weight-700">Forgot Password</h4>
                            <p className="font-weight-600">Please enter your email address below.</p>
                            <div className="form-group">
                              <label className="font-weight-700">E-MAIL *</label>
                              <input name="email" required className="form-control" type="email" value={email} onChange={this.onChange} />
                            </div>
                            <div className="form-group">
                              {this.state.errorMessage && <span className="error"> Please check the email you entered </span>}
                            </div>
                            <br />
                            <div className="text-left">
                              <button type="submit" className="site-button m-r5 button-lg radius-no">
                                Submit
                              </button>
                            </div>
                          </form>
                        )}
                        {is_otp_send ? (
                          <form id="login" className="tab-pane active col-12 p-a0 " onSubmit={this.onSubmitOTP}>
                            <h4 className="font-weight-700">Enter OTP</h4>
                            <br></br>
                            <p className="font-weight-600">Please enter OTP that you got in your email.</p>
                            <br></br>
                            <div className="form-group">
                              <label className="font-weight-700">OTP *</label>
                              <input
                                name="otp_input"
                                required
                                className="form-control"
                                type="text"
                                value={otp_input}
                                onChange={this.onChange}
                                maxLength="4"
                              />
                            </div>
                            <div className="form-group">
                              {this.state.otp_error && <span className="error"> Please check the OTP you entered </span>}
                            </div>
                            <br />
                            <div className="text-left">
                              <button type="submit" className="site-button m-r5 button-lg radius-no">
                                Submit
                              </button>
                            </div>
                          </form>
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 m-b30"></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default ForgotPassword;
