import React, { Component } from "react";
// import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonial1_content = [
  {
    id: 1354655562,
    author: "NTC Supply Chain Solutions Private Limited",
    description:
      " I started using their trailers and tankers. It’s hard for me to imagine anything before Neelkanth Fabricator.  I have told to my number of friends about it already.",
  },
  {
    id: 37373,
    author: "Ashok Roadways",
    description:
      "I run a small business and I quit that long ago because it was expensive and pretty byzantine. I don’t want to spend my hard earned money into these repairing issues. I want to maximize my earning. Neelkanth Fabricator is so functional. I love them!",
  },
  {
    id: 73753,
    author: "N.K. Proteins Pvt Ltd.",
    description:
      "They are the best! The very first thing is the Neelkanth brand name that signifies trust. Also Neelkanth Fabricator come with Icat and IOS certification that ensures well engineered trailers that offers better design and components.",
  },
  {
    id: 737375375,
    author: "Pooniya Roadlines",
    description:
      "The brand gives me the added confidence to buy trailers that are high on performance yet, low on maintenance. Neelkanth Fabricator also provides great after sales service pan India which is very crucial for trailermaintenancein the long run.",
  },
  {
    id: 4387354463,
    author: "B.M Roadlines",
    description:
      "They have the best customization program for tankers and trailers. They gave me the chance to create my personal tanker and trailer making it extra special for me. I will recommend them to my friends.",
  },
  {
    id: 453453,
    author: "Pawa Brokers Pvt. Ltd.",
    description:
      "Great performance! Their trailers incorporate many innovative features, thanks to their cutting-edge manufacturing technology that delivers impressive results.",
  },
  {
    id: 543245321,
    author: "Amit Bulk Carriers ",
    description: "Low maintenance cost! Their maintenance cost are low and operating controls are practical.",
  },
  {
    id: 75434352,
    author: "MS Tanker Transport",
    description:
      "Great Service! Some of the trailers did experience some minor operational issues. However, Neelkanth Fabricator’s team resolved them quickly and efficiently. Their after-sales service is really prompt. ",
  },
  {
    id: 453243512,
    author: "Abhisek Bulk Carriers",
    description:
      "They keep their promise! The tanker that we bought from Neelkanth Fabricator was not functioning well. Their team gave us a prompt service. They are passionate about making promises stick. They don’t make promises that they can’t keep.",
  },
  {
    id: 645356365,
    author: "Jatin Bulk Carriers",
    description:
      "Keep up the good work! We have already placed our next schedule with the company and are very much impressed with their customer handling and after sales services. The kind of human touch that Neelkanth Fabricator endures with customers and their representatives has won our hearts.",
  },
];

class Testmonial2 extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: 3000,
    };
    return (
      <>
        <Slider {...settings} className="testimonial-box-carousel btn-style-1 client-btn">
          {Testimonial1_content.map((item, id) => (
            <div className="item" key={id}>
              <div className="testimonial-11 testimonial-box">
                <div className="testimonial-detail quote-left quote-right">
                  <div className="testimonial-text">
                    <p>{item.description}</p>
                  </div>
                  <h5 className="testimonial-name text-primary m-t0 m-b5">{item.author}</h5>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </>
    );
  }
}
export default Testmonial2;
