import React, { Component, useState } from "react";
import { connect } from "react-redux";
import API from "../../Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const logo = require("../../images/REGISTER_LOGO.png");
const notifys = () => toast("Order has been placed!", { position: "bottom-right" });

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}
class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: {},
      localStorageCart: JSON.parse(localStorage.getItem("cart")),
      full_name: "",
      last_name: "",
      address_line1: "",
      address_line2: "",
      city: "",
      state: "",
      pin_code: "",
      email: "",
      phone: "",
      is_address: false,
      is_gujarat: true,
      cart_order_sub_total: 0,
      cart_order_tax_total: 0,
      freight_charge: 0,
      address_pin_code: 0,
      button_text: "Place order now!",
      payment_mode: "Online",
      razorpay_response: [],
    };
  }
  async componentDidMount() {
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    API.get(`user/user-info/${localStorage.getItem("user_id")}`)
      .then((data) => {
        console.log(data);
        this.setState({ userInfo: data });
        // this.setState({ name: data.name })
        if (data.address) {
          this.setState({ address_pin_code: data.address.pin_code });

          this.setState({ email: data.email });
          this.setState({ phone: data.address.mobile_number });
          this.setState({ full_name: data.address.full_name });
          this.setState({ pin_code: data.address.pin_code });
          this.setState({ address_line1: data.address.address1 });
          this.setState({ address_line2: data.address.address2 });
          this.setState({ address_landmark: data.address.landmark });

          this.setState({ address_state: data.address.state });
          this.setState({ is_address: true });
          let stateID = data.address.state;
          API.get(`common/getStates/${stateID}`).then((res) => {
            console.log("resresresresres", res);
            if (res) {
              let StateName = res[0].name;
              this.setState({ state: StateName });
              if (StateName == "Gujarat") {
                this.setState({ is_gujarat: true });
              } else {
                this.setState({ is_gujarat: false });
              }
            }
          });
          API.get(`common/getCity/${data.address.city}`).then((res) => {
            console.log("resresresresres", res);
            if (res) {
              let CityName = res[0].name;
              this.setState({ city: CityName });
            }
          });
        }
      })
      .then(() => {
        this.cal_freight_charge();
      });
    let { localStorageCart, is_gujarat } = this.state;
    let cart_order_sub_total = 0;
    let taxTotal = 0;
    let vatotal =
      localStorageCart.length !== 0
        ? localStorageCart.products.map((item) => {
            let tax = 0;
            if (is_gujarat) {
              tax = item.Sgst + item.Cgst;
            } else {
              tax = item.Igst;
            }
            taxTotal += ((item.price * item.quantity) / 100) * tax;
            cart_order_sub_total += item.price * item.quantity;
          })
        : null;
    this.setState({ cart_order_sub_total: cart_order_sub_total });
    this.setState({ cart_order_tax_total: taxTotal });
  }

  onChange = (e) => {
    /*
          Because we named the inputs to match their
          corresponding values in state, it's
          super easy to update the state
        */
    this.setState({ [e.target.name]: e.target.value });
  };

  PaymentMethodChange = (e) => {
    if (e.target.value === "Razorpay") {
      this.setState({ button_text: "Proceed to pay Online!" });
      this.setState({ payment_mode: "Online" });
    } else {
      this.setState({ button_text: "Place order now!" });
      this.setState({ payment_mode: "COD" });
    }
  };
  onSubmit = async (e) => {
    e.preventDefault();
    const { payment_mode, cart_order_sub_total, cart_order_tax_total, freight_charge, razorpay_response } = this.state;
    let FinalTotal = cart_order_sub_total + cart_order_tax_total + freight_charge;
    let dataToSend = {
      user_id: localStorage.getItem("user_id"),
      user_order_address: {
        full_name: `${this.state.full_name}`,
        email: this.state.email,
        mobile_number: this.state.phone,
        pin_code: this.state.pin_code,
        address1: this.state.address_line1,
        address2: this.state.address_line2,
        city: this.state.city,
        state: this.state.state,
      },
      payment: {
        mode: payment_mode,
        price: FinalTotal,
      },
      products: this.state.localStorageCart.products,
      subtotal: cart_order_sub_total,
      tax: cart_order_tax_total,
      final_total: FinalTotal,
      freight_charge: freight_charge,
    };
    if (payment_mode === "Online") {
      this.displayRazorpay(dataToSend).then((data) => {
        dataToSend.payment.razorpayData = data;
        dataToSend.payment.transaction_id = data.razorpay_payment_id;
        dataToSend.payment.status = "Inreview";
        API.post("order/post", dataToSend)
          .then((res) => {
            notifys();
            localStorage.removeItem("cart");
            this.props.history.push(`/orders`);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    } else {
      API.post("order/post", dataToSend)
        .then((res) => {
          notifys();
          localStorage.removeItem("cart");
          this.props.history.push(`/orders`);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  displayRazorpay = async (dataToSend) => {
    return new Promise(async (resolve, reject) => {
      try {
        const MobileNumber = dataToSend.user_order_address.mobile_number;
        const Email = dataToSend.user_order_address.email;

        API.post(`common/razorpay-data`, dataToSend).then((data) => {
          const options = {
            key: "rzp_live_oKKKSWLY2PolrO",
            currency: "INR",
            amount: data.amount.toString(),
            order_id: data.id,
            name: "Neelkanth F.",
            description: `Order #${data.id}`,
            image: logo,
            handler: function (response) {
              resolve(response);
              // alert(response.razorpay_payment_id);
              // alert(response.razorpay_order_id);
              // alert(response.razorpay_signature);
            },
            prefill: {
              email: Email,
              contact: MobileNumber,
            },
            theme: {
              color: "#163883",
            },
          };
          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
        });
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };
  cal_freight_charge() {
    const { localStorageCart } = this.state;
    let totalWeight = 1;
    let total =
      localStorageCart.length !== 0
        ? localStorageCart.products.map((item) => {
            totalWeight += item.weight * item.quantity;
          })
        : null;
    console.log("totalWeighttotalWeight", totalWeight);
    // if (Data > 0) {
    API.get(`common/check-courier-serviceability/${this.state.address_pin_code}/${totalWeight}/0`).then((res) => {
      if (res) {
        if (res.status == 404) {
          this.setState({ freight_charge: 0 });
        } else {
          this.setState({ freight_charge: res.data });
        }
      }
    });
    // }
  }
  render() {
    const {
      full_name,
      last_name,
      address_line1,
      address_line2,
      city,
      state,
      pin_code,
      email,
      phone,
      localStorageCart,
      is_address,
      is_gujarat,
      freight_charge,
      button_text,
    } = this.state;
    let cart_order_sub_total = 0;
    let taxTotal = 0;
    let vatotal =
      localStorageCart.length !== 0
        ? localStorageCart.products.map((item) => {
            let tax = 0;
            if (is_gujarat) {
              tax = item.Sgst + item.Cgst;
            } else {
              tax = item.Igst;
            }
            taxTotal += ((item.price * item.quantity) / 100) * tax;
            cart_order_sub_total += item.price * item.quantity;
          })
        : null;
    let addedItems = this.state.localStorageCart.products.length
      ? this.state.localStorageCart.products.map((item) => {
          return (
            <tr>
              <td>
                <img src={item.images[0].url} alt="" />
              </td>
              <td>{item.name}</td>
              <td class="product-price">₹ {item.price}</td>
            </tr>
          );
        })
      : this.props.history.push(`/`);
    return (
      <>
        <div class="page-content bg-white">
          <ToastContainer />
          <div class="section-full content-inner">
            <div class="container">
              <form class="shop-form" onSubmit={this.onSubmit}>
                {/*<form class="shop-form">*/}
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-md-6 m-b30">
                    <h4>Billing & Shipping Address</h4>
                    <div class="row">
                      <div class="form-group col-md-12">
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          placeholder="First Name"
                          name="full_name"
                          value={full_name}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        disabled
                        placeholder="Address"
                        name="address_line1"
                        value={address_line1}
                        onChange={this.onChange}
                      />
                    </div>
                    <div class="row">
                      <div class="form-group col-md-6">
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          placeholder="Apartment, suite, unit etc."
                          name="address_line2"
                          value={address_line2}
                          onChange={this.onChange}
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          placeholder="Town / City"
                          name="city"
                          value={city}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-md-6">
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          placeholder="State / County"
                          name="state"
                          value={state}
                          onChange={this.onChange}
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          placeholder="Postcode / Zip"
                          name="pin_code"
                          value={pin_code}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-md-12">
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          placeholder="Phone"
                          name="phone"
                          value={phone}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="dlab-divider bg-gray-dark text-gray-dark icon-center">
                  <i class="fa fa-circle bg-white text-gray-dark" />
                </div>
                <div class="row">
                  <div class="col-lg-6 m-b15">
                    <h4>Your Order</h4>
                    <table class="table-bordered check-tbl">
                      <thead>
                        <tr>
                          <th>IMAGE</th>
                          <th>PRODUCT NAME</th>
                          <th>TOTAL</th>
                        </tr>
                      </thead>
                      <tbody>{addedItems}</tbody>
                    </table>
                  </div>
                  <div class="col-lg-6 m-b15">
                    <h4>Order Total</h4>
                    <table class="table-bordered check-tbl">
                      <tbody>
                        <tr>
                          <td>Order Subtotal</td>
                          <td>₹ {cart_order_sub_total}</td>
                        </tr>
                        <tr>
                          <td>Tax</td>
                          <td>₹ {taxTotal}</td>
                        </tr>
                        <tr>
                          <td>Freight Charges</td>
                          <td>
                            ₹ &nbsp;
                            {freight_charge}
                          </td>
                        </tr>
                        <tr>
                          <td>Total</td>
                          <td>
                            ₹ &nbsp;
                            {(freight_charge + cart_order_sub_total + taxTotal).toFixed(2)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <h5>Payment Method</h5>
                    <div class="form-group">
                      <select onChange={this.PaymentMethodChange} class="form-control" ref="payment_method">
                        <option value="Razorpay">Razorpay</option>
                      </select>
                    </div>
                    <div class="form-group">
                      {is_address ? (
                        <button class="site-button button-lg btn-block" type="submit">
                          {button_text}
                        </button>
                      ) : (
                        <li class="site-button button-lg btn-block">Please Add Address First </li>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.addedItems,
    total: state.total,
    //addedItems: state.addedItems
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
// export default Checkout;
