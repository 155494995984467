import React, { Component } from "react";
import API from "../../Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const notifys = () =>
  toast("We will get back to you Soon !", { position: "bottom-right" });
class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      mobile_number: "",
      email_id: "",
      subject: "",
      message: "",
      type: "Contact",
    };
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onSubmit = async (e) => {
    e.preventDefault();
    const { name, mobile_number, email_id, subject, message, type } =
      this.state;
    let dataToSend = {
      name: name,
      mobile_number: mobile_number,
      email_id: email_id,
      subject: subject,
      message: message,
      type: type,
    };
    API.post("common/saveContactUs", dataToSend)
      .then((res) => {
        notifys();
        console.log(res);
        this.setState({
          name: "",
          mobile_number: "",
          email_id: "",
          subject: "",
          message: "",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  render() {
    const { name, mobile_number, email_id, subject, message } = this.state;
    return (
      <>
        <div className="page-content bg-white">
          <ToastContainer />
          <div className="section-full content-inner contact-page-8 bg-white">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-12">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 m-b30">
                      <div className="icon-bx-wraper expertise bx-style-1 p-a20 radius-sm">
                        <div className="icon-content">
                          <h5 className="dlab-tilte">
                            <span className="icon-sm text-primary">
                              <i className="ti-info-alt"></i>
                            </span>
                            Company Info
                          </h5>
                          <p>Neelkanth Fabricator</p>
                          <p>GST No. 24AOHPH7892G1ZO</p>
                          <br></br>
                          <h5 className="dlab-tilte">
                            <span className="icon-sm text-primary">
                              <i className="ti-location-pin"></i>
                            </span>
                            Company Address
                          </h5>
                          <p>
                            Plot No. 51,52,53 G.I.D.C Anjar - 370 110 Kutch
                            (Gujarat)
                          </p>
                          <br></br>
                          <h5 className="dlab-tilte">
                            <span className="icon-sm text-primary">
                              <i className="ti-alarm-clock"></i>
                            </span>
                            Office Hours
                          </h5>
                          <p>Mon To Sat - 10.00 - 07.00</p>
                          <p>Sunday - Close</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-6 m-b30">
                      <div className="icon-bx-wraper expertise bx-style-1 p-a20 radius-sm">
                        <div className="icon-content">
                          <h5 className="dlab-tilte">
                            <span className="icon-sm text-primary">
                              <i className="ti-email"></i>
                            </span>
                            E-mail
                          </h5>
                          <p>info@neelkanthfabricators.com</p>
                          <p>sales@neelkanthfabricators.com</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-6 m-b30">
                      <div className="icon-bx-wraper expertise bx-style-1 p-a20 radius-sm">
                        <div className="icon-content">
                          <h5 className="dlab-tilte">
                            <span className="icon-sm text-primary">
                              <i className="ti-mobile"></i>
                            </span>
                            Phone Numbers
                          </h5>
                          <p>
                            Service & Maintenance:- 98252 26577 & 70418 41002
                          </p>
                          <p>New Product:- 90339 96421 & 98077 98076</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-12 m-b30">
                  <form
                    className="inquiry-form wow fadeInUp"
                    data-wow-delay="0.2s"
                    onSubmit={this.onSubmit}
                  >
                    <h3 className="title-box font-weight-300 m-t0 m-b10">
                      Let's Convert Your Idea into Reality{" "}
                      <span className="bg-primary"></span>
                    </h3>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="ti-user text-primary"></i>
                            </span>
                            <input
                              name="name"
                              type="text"
                              required
                              className="form-control"
                              placeholder="Name"
                              value={name}
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="ti-mobile text-primary"></i>
                            </span>
                            <input
                              name="mobile_number"
                              type="number"
                              required
                              className="form-control"
                              placeholder="Phone"
                              value={mobile_number}
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="ti-email text-primary"></i>
                            </span>
                            <input
                              name="email_id"
                              type="email"
                              className="form-control"
                              required
                              placeholder="Your Email Id"
                              value={email_id}
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="ti-email text-primary"></i>
                            </span>
                            <input
                              name="subject"
                              type="text"
                              className="form-control"
                              required
                              placeholder="Subject"
                              value={subject}
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="ti-agenda text-primary"></i>
                            </span>
                            <textarea
                              name="message"
                              rows="4"
                              className="form-control"
                              required
                              placeholder="Tell us about your project or idea"
                              value={message}
                              onChange={this.onChange}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <button
                          name="submit"
                          type="submit"
                          value="Submit"
                          className="site-button button-md"
                        >
                          {" "}
                          <span>Contact Us</span>{" "}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-lg-12 col-md-12">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3085.364551910628!2d70.03601987443844!3d23.128649656871257!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3950c0b02dd1e4d3%3A0x1de4949d63a41f94!2sNeelkanth%20Fabricators!5e0!3m2!1sen!2sin!4v1600099170417!5m2!1sen!2sin"
                    width="1314"
                    height="607"
                    frameBorder="0"
                    allowFullScreen=""
                    aria-hidden="false"
                    title="no"
                  ></iframe>
                </div>
              </div> */}
              <div class="map-responsive">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d749.3827707169532!2d70.03761021504559!3d23.12861162112172!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3950c0b02dd1e4d3%3A0x1de4949d63a41f94!2sNeelkanth%20Fabricators!5e0!3m2!1sen!2sin!4v1600752164246!5m2!1sen!2sin"
                  width="600"
                  height="450"
                  frameborder="0"
                  allowfullscreen=""
                  aria-hidden="false"
                  tabindex="0"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Contact;
