import React, { Component, useMemo } from "react";
import { Link } from "react-router-dom";
import { Accordion, AccordionItem } from "react-sanfona";
import "nouislider/distribute/nouislider.css";
import API from "../../Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addToCart, addItemsToState } from "../../actions/cartActions";
import { connect } from "react-redux";
const loaderIMG = require("../../images/Loading_icon.gif");
const notify = () => toast("Product Added To cart !", { position: "bottom-right" });
class Shop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      sub_cats: [],
    };
  }
  componentDidMount() {
    this.fetchProduct();
    this.fetchSubCat();
  }

  componentWillReceiveProps(nextProps, prevState) {
    if (nextProps.match.params.catslug !== this.props.match.params.catslug) {
      let catId = nextProps.match.params.catslug;
      this.fetchProductAfterComp(nextProps, null);
      API.get(`category/sub/${catId}`).then((data) => {
        this.setState({ sub_cats: data });
      });
    }
  }
  fetchProductAfterComp = (nextProps, subCatId) => {
    console.log(nextProps, subCatId);
    if (!subCatId) {
      if (nextProps) {
        let catId = nextProps.match.params.catslug;
        API.get(`products/get/cat/${catId}`).then((data) => {
          this.props.addItemsToState(data);
          this.setState({ data: data });
        });
      }
    }
    if (subCatId) {
      API.get(`products/get/subcat/${subCatId}`).then((data) => {
        this.props.addItemsToState(data);
        this.setState({ data: data });
      });
    }
  };

  fetchProduct = () => {
    let catId = this.props.match.params.catslug;
    API.get(`products/get/cat/${catId}`).then((data) => {
      this.props.addItemsToState(data);
      this.setState({ data: data });
    });
  };
  fetchSubCat = () => {
    let catId = this.props.match.params.catslug;
    API.get(`category/sub/${catId}`).then((data) => {
      this.setState({ sub_cats: data });
    });
  };
  loadSubCatProd = (SubCatId) => {
    API.get(`products/get/cat/${SubCatId}`).then((data) => {
      this.props.addItemsToState(data);
      this.setState({ data: data });
    });
  };
  handleCartClick = (id, data) => {
    console.log(id);
    this.props.addToCart(id, data);
    notify();
  };
  render() {
    const mystyle = {
      cursor: "pointer",
    };
    return (
      <>
        <ToastContainer />
        <div className="page-content bg-white">
          {/* <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">{catslug}</h1> 
                            </div>
                        </div>
                    </div> */}

          <div className="section-full content-inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-4 m-b30">
                  <aside className="side-bar shop-categories sticky-top">
                    <div className="widget recent-posts-entry">
                      <div className="dlab-accordion advanced-search toggle" id="accordion1">
                        <Accordion allowMultiple className="p-filter dlab-accordion advanced-search toggle">
                          <AccordionItem expanded className="filter-title" title=" Product categories" titleTag="div">
                            <div className="acod-content">
                              <div className="widget widget_services">
                                <ul>
                                  {this.state.sub_cats.length === 0 ? (
                                    <img src={loaderIMG} alt="" className="LoaderCSS" />
                                  ) : (
                                    this.state.sub_cats.map((subCat) => (
                                      <li
                                        style={mystyle}
                                        onClick={() => {
                                          this.fetchProductAfterComp(null, subCat.category_id);
                                        }}
                                      >
                                        {subCat.name}
                                      </li>
                                    ))
                                  )}
                                </ul>
                              </div>
                            </div>
                          </AccordionItem>
                        </Accordion>
                      </div>
                    </div>
                  </aside>
                </div>
                <div className="col-lg-9 col-md-8 m-b30">
                  <div className="row">
                    {this.state.data.length === 0 ? (
                      <img src={loaderIMG} alt="" className="LoaderCSS" />
                    ) : (
                      this.state.data.map((product) => (
                        <div className="col-lg-4 col-md-6 col-sm-6">
                          <div className="item-box m-b10">
                            <div className="item-img">
                              <Link to={`/product/${product.product_id}`}>
                                <img src={product.images[0].url} alt="" />
                              </Link>
                              <div className="item-info-in center">
                                <ul>
                                  {product.is_quotation ? null : (
                                    <li>
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          this.handleCartClick(product.product_id, product);
                                        }}
                                      >
                                        <i className="ti-shopping-cart"></i>
                                      </Link>
                                    </li>
                                  )}

                                  <li>
                                    <Link to={`/product/${product.product_id}`}>
                                      <i className="ti-eye"></i>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="item-info text-center text-black p-a10">
                              <h6 className="item-title font-weight-500">
                                <Link to="/shop-product-details"> {product.name}</Link>
                              </h6>

                              <h4 className="item-price">
                                <span className="text-primary">₹ {product.price}</span>
                              </h4>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.items,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (id, data) => {
      dispatch(addToCart(id, data));
    },
    addItemsToState: (data) => {
      dispatch(addItemsToState(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Shop);
// export default Shop;
