import React, { Component } from "react";
import { Link } from "react-router-dom";
import Sticky from "react-stickynode";
import API from "../../Api";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: [],
      catTree: "",
      UserName: "",
    };
  }
  logoutUser = () => {
    localStorage.removeItem("user_id");
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    window.location.reload();
  };
  componentWillReceiveProps(nextProps, prevState) {
    console.log("im changes");
  }
  componentDidMount() {
    let currentComponent = this;
    this.setState({ UserName: localStorage.getItem("name") });
    //localStorage.setItem("cart", JSON.stringify({ products: [], total: 14000 }));
    API.get("category/get").then((data) => {
      this.setState({ category: data });

      let AllCategories = this.state.category;

      let tree = [];
      AllCategories.forEach((element) => {
        if (element.is_main === true) {
          let tree1 = [];
          AllCategories.forEach((element1) => {
            if (element.category_id === element1.parent) {
              tree1.push({ key: element1.category_id, title: element1.name });
            }
          });
          tree.push({
            cat_id: element.category_id,
            main: element.name,
            sub: tree1,
          });
        }
      });
      this.setState({ category: tree });
      let catTree = "";
      tree.map((cat) => {
        catTree += `<li>`;
        catTree += `<Link to="#">${cat.main}<i className="fa fa-angle-right"></i></Link>`;
        catTree += `</li>`;
      });
      this.setState({ catTree: catTree });
    });

    // sidebar open/close

    var btn = document.querySelector(".navicon");
    var nav = document.querySelector(".header-nav");

    function toggleFunc() {
      btn.classList.toggle("open");
      nav.classList.toggle("show");
    }

    btn.addEventListener("click", toggleFunc);

    // Sidenav li open close
    var navUl = [].slice.call(document.querySelectorAll(".header-nav > ul > li"));
    for (var y = 0; y < navUl.length; y++) {
      navUl[y].addEventListener("click", function () {
        checkLi(this);
      });
    }

    function checkLi(current) {
      const active = current.classList.contains("open");
      navUl.forEach((el) => el.classList.remove("open"));
      //current.classList.add('open');
      currentComponent.setState({ UserName: localStorage.getItem("name") });
      if (active) {
        current.classList.remove("open");
        console.log("active");
      } else {
        current.classList.add("open");
        console.log("close");
      }
    }
  }

  render() {
    let UserName = this.state.UserName;
    console.log("UserNameUserNameUserNameUserName", UserName);
    let cats = this.state.category;
    function DropdownItem() {
      return cats.length === 0
        ? "Loading Cats..."
        : cats.map((cats, id) => (
            <li key={id}>
              <Link key={id} to={`/category/${cats.cat_id}`}>
                {cats.main}
              </Link>
            </li>
          ));
    }
    return (
      <>
        <header className="site-header mo-left header ext-header navstyle3">
          <div className="middle-bar bg-white">
            <div className="container">
              <div className="middle-area">
                <div className="logo-header">
                  <Link to="/">
                    <img src={require("../../images/REGISTER_LOGO.png")} alt="" />
                  </Link>
                </div>
                <div className="service-list">
                  <ul>
                    <li>
                      <i className="la la-phone"></i>
                      <h4 className="title">+91-90339-96421</h4>
                      <span>&nbsp;</span>
                    </li>
                    <li>
                      <i className="la la-clock-o"></i>
                      <h4 className="title">10:00 - 07:00</h4>
                      <span>Monday - Friday</span>
                    </li>
                    <li>
                      <i className="la la-map"></i>
                      <h4 className="title">Plot No. 51,52,53 G.I.D.C</h4>
                      <span>Anjar-370110, Kutch (Gujarat)</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <Sticky innerZ={999} enabled={true}>
            <div className="sticky-header main-bar-wraper navbar-expand-lg">
              <div className="main-bar clearfix ">
                <div className="container clearfix">
                  <div className="logo-header mostion">
                    <Link to="/">
                      <img src={require("../../images/REGISTER_LOGO.png")} alt="" />
                    </Link>
                  </div>

                  <button
                    className="navbar-toggler collapsed navicon justify-content-end"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>
                  <div className="dlab-quik-search "></div>

                  <div className="header-nav navbar-collapse collapse justify-content-start" id="navbarNavDropdown">
                    <div className="logo-header d-md-block d-lg-none">
                      <Link to="/">
                        <img src={require("../../images/REGISTER_LOGO.png")} alt="" />
                      </Link>
                    </div>
                    <ul className="nav navbar-nav">
                      <li className="has-mega-menu homedemo">
                        {" "}
                        <Link to="/">Home</Link>
                      </li>
                      <li className="has-mega-menu homedemo">
                        {" "}
                        <Link to="/primary-product">Primary Product</Link>
                      </li>
                      <li>
                        <Link to="#">
                          Shop<i className="fa fa-chevron-down"></i>
                        </Link>
                        <ul className="sub-menu tab-content">
                          <DropdownItem></DropdownItem>
                        </ul>
                      </li>
                      <li className="has-mega-menu homedemo">
                        <Link to="/gallery">Gallery</Link>{" "}
                      </li>
                      <li className="has-mega-menu homedemo">
                        <Link to="/about-us">About Us</Link>{" "}
                      </li>

                      <li className="has-mega-menu homedemo">
                        <Link to="/contact-us">Contact Us</Link>{" "}
                      </li>
                      {UserName ? (
                        <li className="sub-menu tab-content">
                          <Link to="#">
                            Hi {UserName}!<i className="fa fa-chevron-down"></i>
                          </Link>
                          <ul className="sub-menu tab-content">
                            <li>
                              <Link to="/user-profile">Profile</Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                onClick={() => {
                                  this.logoutUser();
                                }}
                              >
                                Logout
                              </Link>
                            </li>
                          </ul>
                        </li>
                      ) : (
                        <li className="has-mega-menu homedemo">
                          <Link to="/login">Login</Link>{" "}
                        </li>
                      )}
                      <li className="has-mega-menu homedemo">
                        <Link to="/cart">Cart</Link>{" "}
                      </li>
                    </ul>
                    <div className="dlab-social-icon ind-social">
                      <ul>
                        <li>
                          <Link className="site-button-link facebook fa fa-facebook" to="/javascript:void(0);"></Link>
                        </li>
                        <li>
                          <Link className="site-button-link twitter fa fa-twitter" to="/javascript:void(0);"></Link>
                        </li>
                        <li>
                          <Link className="site-button-link linkedin fa fa-linkedin" to="/javascript:void(0);"></Link>
                        </li>
                        <li>
                          <Link className="site-button-link instagram fa fa-instagram" to="/javascript:void(0);"></Link>
                        </li>
                      </ul>
                      <p>2020 NKFC</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Sticky>
        </header>
      </>
    );
  }
}
export default Header;
