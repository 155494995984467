
import { ADD_ITEMS_TO_STATE,ADD_TO_CART,REMOVE_ITEM,SUB_QUANTITY,ADD_QUANTITY,ADD_SHIPPING} from './action-types/cart-actions'


//add items to state
export const addItemsToState= (data)=>{ 
    return{
        type: ADD_ITEMS_TO_STATE,
        data
    }
}
//add cart action
export const addToCart= (id,data)=>{ 
    return{
        type: ADD_TO_CART,
        id,
        data
    }
}
//remove item action
export const removeItem=(id,data)=>{
    return{
        type: REMOVE_ITEM,
        id,
        data
    }
}
//subtract qt action
export const subtractQuantity=(id,data)=>{
    return{
        type: SUB_QUANTITY,
        id,
        data
    }
}
//add qt action
export const addQuantity=(id,data)=>{
    return{
        type: ADD_QUANTITY,
        id,
        data
    }
}
