import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Table } from "reactstrap";
import API from "../../../Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import 'react-tabs/style/react-tabs.css';
import "../../../css/skin/testCheckout/main.css";

class userPanelOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      orders: [],
    };
  }
  componentDidMount() {
    let orderId = this.props.match.params.orderId;
    console.log("orderIdorderId", orderId);
    API.get(`order/customer/get/${localStorage.getItem("user_id")}`).then((data) => {
      console.log(data);
      this.setState({ orders: data });
    });
  }

  render() {
    return (
      <>
        <div id="account-page" className="section">
          <ToastContainer />
          {/* Container */}
          <div className="container">
            {/* Account Layout */}
            <div className="columns account-header">
              <div className="column main-column is-tablet-landscape-padded">
                {/* Title */}
                <div className="account-title">
                  <h2>ACCOUNT</h2>
                  <img className="brand-filigrane" src="assets/img/logo/nephos-greyscale.svg" alt="" />
                </div>
                {/* Account tabs */}
                <div className="tabs account-tabs">
                  <ul>
                    <li>
                      {" "}
                      <Link to="/user-profile">Account</Link>
                    </li>
                    <li>
                      {" "}
                      <Link to="/orders">Orders</Link>
                    </li>
                  </ul>
                </div>
                <div id="account-main-placeholder" className="main-placeholder is-hidden">
                  <div className="placeholder-content">
                    <img src="assets/img/illustrations/profile.svg" alt="" />
                    <h3>Welcome, Guest</h3>
                    <p>Guest users don't have accounts. Please login or register to view your account.</p>
                    <div className="button-wrap">
                      <a href="authentication.html" className="button big-button primary-button rounded raised">
                        Log In
                      </a>
                    </div>
                    <a>Don't have an account?</a>
                  </div>
                </div>
                <div id="orders-main" className="columns is-account-grid is-multiline">
                  <div className="account-loader is-active is-hidden">
                    <div className="loader is-pulled is-loading" />
                  </div>
                  <div className="column is-12">
                    {this.state.orders.length === 0 ? (
                      <div id="orders-empty-placeholder" className="main-placeholder">
                        <div className="placeholder-content">
                          <h3>No Orders</h3>
                          <p>You have no orders to show. Your order details will be stored here, once you checkout.</p>
                          <div className="button-wrap">
                            <a href="/" className="button big-button primary-button rounded raised">
                              Continue Shopping
                            </a>
                          </div>
                        </div>
                      </div>
                    ) : (
                      this.state.orders.map((order, s) => (
                        <div className="order-long-card" key={s}>
                          <div className="left-side">
                            <div className="order-header">
                              <h3>ORDER #{order.order_id}</h3>
                              <span className="date">{order.created_at}</span>
                              <span className="tag is-primary">{order.status}</span>
                              <span className="order-total">{order.final_total.toFixed(2)}</span>
                            </div>
                            <div className="ordered-products has-slimscroll">
                              <div className="products-loader">
                                <div className="loader is-loading" />
                              </div>
                              {order.products === 0
                                ? null
                                : order.products.map((product, i) => (
                                    <div className="ordered-product" key={i}>
                                      <img src={product.images[0].url} alt="" />
                                      <div className="product-meta">
                                        <span className="name">{product.name}</span>
                                        <span className="price">
                                          <span>{product.price}</span>
                                          <span>
                                            x <var>{product.quantity}</var>
                                          </span>
                                        </span>
                                      </div>
                                      <div className="product-subtotal" key={i}>
                                        <span>Total</span>
                                        <span>{(product.price * product.quantity).toFixed(2)}</span>
                                        <span className="tag is-primary">{product.product_status}</span>
                                      </div>
                                    </div>
                                  ))}
                            </div>
                          </div>
                          <div className="right-side">
                            <img className="side-bg" src="assets/img/logo/nephos-greyscale.svg" alt="" />
                            <div className="meta-header">
                              <div className="inner-meta">
                                <span>Ordered by</span>
                                <span>{order.user_order_address.full_name}</span>
                              </div>
                              <a className="support">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-life-buoy"
                                >
                                  <circle cx={12} cy={12} r={10} />
                                  <circle cx={12} cy={12} r={4} />
                                  <line x1="4.93" y1="4.93" x2="9.17" y2="9.17" />
                                  <line x1="14.83" y1="14.83" x2="19.07" y2="19.07" />
                                  <line x1="14.83" y1="9.17" x2="19.07" y2="4.93" />
                                  <line x1="14.83" y1="9.17" x2="18.36" y2="5.64" />
                                  <line x1="4.93" y1="19.07" x2="9.17" y2="14.83" />
                                </svg>
                              </a>
                            </div>
                            <div className="meta-actions">
                              <Link
                                className="button is-rounded is-fullwidth primary-button raised order-details-link"
                                to={`/order/${order.order_id}`}
                              >
                                Order Details
                              </Link>
                              {/* <a className="button is-rounded is-fullwidth grey-button rounded">Invoice</a> */}
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default userPanelOrder;
