import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Table } from "reactstrap";
import API from "../../../Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import 'react-tabs/style/react-tabs.css';
import "../../../css/skin/testCheckout/main.css";

class userPanelOrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      orders: {
        payment: {},
        products: {},
        user_order_address: {},
      },
    };
  }
  componentDidMount() {
    let orderId = this.props.match.params.orderId;
    console.log("orderIdorderId", orderId);
    API.get(`order/order/get/${orderId}`).then((data) => {
      console.log(data);
      this.setState({ orders: data });
    });
  }
  getParsedDate(strDate) {
    var strSplitDate = String(strDate).split(" ");
    var date = new Date(strSplitDate[0]);
    // alert(date);
    var dd = date.getDate();
    var mm = date.getMonth() + 1; //January is 0!

    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    date = dd + "-" + mm + "-" + yyyy;
    return date.toString();
  }
  render() {
    const { orders } = this.state;
    console.log(orders.products);
    let productsArr = orders.products;
    var ProductDiv = Object.keys(productsArr).map(function (key) {
      console.log(productsArr[key].product_id);
      return (
        <div className="flex-table-item" data-product-id={107} key={key}>
          <div className="product">
            <span className="product-name">{productsArr[key].name}</span>
          </div>
          <div className="quantity">
            <span>{productsArr[key].quantity}</span>
          </div>
          <div className="price">
            <span className="has-price">{productsArr[key].price}</span>
          </div>
          <div className="total">
            <span className="has-price">{productsArr[key].price * productsArr[key].quantity}</span>
          </div>
          <div style={{ width: "15%", display: "flex", justifyContent: "center", alignItems: "center", borderLeft: "1px solid #e3e3e3" }}>
            <span className="tag is-primary">{productsArr[key].product_status}</span>
          </div>
        </div>
      );
    });
    return (
      <>
        <div id="account-page" className="section">
          <ToastContainer />
          {/* Container */}
          <div className="container">
            {/* Account Layout */}
            <div className="columns account-header">
              <div className="column main-column is-tablet-landscape-padded">
                <div className="account-title">
                  <Link className="title-back" to={`/orders`}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-chevron-left"
                    >
                      <polyline points="15 18 9 12 15 6" />
                    </svg>
                  </Link>

                  <h2>ORDER DETAILS</h2>

                  {/* <div className="ml-auto">
                    <div className="order-top-actions">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <a>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-printer"
                        >
                          <polyline points="6 9 6 2 18 2 18 9" />
                          <path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2" />
                          <rect x={6} y={14} width={12} height={8} />
                        </svg>{" "}
                        Print Order
                      </a>
                    </div>
                  </div> */}
                </div>
                {/*Header*/}
                <div className="order-details-wrap">
                  <div className="order-details-header">
                    <div className="left">
                      <span id="order-details-id">
                        ORDER <var>{orders.order_id}</var>
                      </span>
                      <span id="order-details-date">
                        Issued on <var>{this.getParsedDate(orders.updated_at)}</var>
                      </span>
                    </div>
                    <div className="right">
                      {/* <img
                        id="order-details-avatar"
                        src="assets/img/avatars/janet.jpg"
                        data-demo-src="assets/img/avatars/janet.jpg"
                        alt=""
                      /> */}
                      <div className="inner-meta">
                        <span>Orderd by</span>
                        <span id="order-details-contact"> {orders.user_order_address.full_name}</span>
                      </div>
                    </div>
                  </div>
                  <div className="order-stats">
                    <div className="columns">
                      <div className="column is-6">
                        <div id="payment-tile" className="order-stat">
                          <h3>Payment</h3>
                          <div className="order-stat-inner">
                            <div className="icon-container">
                              <div className="indicator">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-check check"
                                >
                                  <polyline points="20 6 9 17 4 12" />
                                </svg>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-x error"
                                >
                                  <line x1={18} y1={6} x2={6} y2={18} />
                                  <line x1={6} y1={6} x2={18} y2={18} />
                                </svg>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-x warning"
                                >
                                  <line x1={18} y1={6} x2={6} y2={18} />
                                  <line x1={6} y1={6} x2={18} y2={18} />
                                </svg>
                              </div>
                            </div>
                            <div className="meta">
                              <span>Payment</span>
                              <span>{orders.payment.status}</span>
                              <span>{orders.payment.mode}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="column is-6">
                        <div id="status-tile" className="order-stat">
                          <h3>Status</h3>
                          <div className="order-stat-inner">
                            <div className="icon-container">
                              <img src="assets/img/icons/line/status.svg" alt="" />
                              <div className="indicator">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-check check"
                                >
                                  <polyline points="20 6 9 17 4 12" />
                                </svg>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-x error"
                                >
                                  <line x1={18} y1={6} x2={6} y2={18} />
                                  <line x1={6} y1={6} x2={18} y2={18} />
                                </svg>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-x warning"
                                >
                                  <line x1={18} y1={6} x2={6} y2={18} />
                                  <line x1={6} y1={6} x2={18} y2={18} />
                                </svg>
                              </div>
                            </div>
                            <div className="meta">
                              <span>Status</span>
                              <span>{orders.status}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="order-body">
                    <div className="columns">
                      <div className="column is-8">
                        <div className="left-side-header">
                          <h3>Products</h3>
                        </div>
                        <div className="flex-table">
                          {/*Table header*/}
                          <div className="flex-table-header">
                            <span className="product">
                              <span>Product</span>
                            </span>
                            <span className="quantity">Quantity</span>
                            <span className="price">Price</span>
                            <span className="total">Total</span>
                            <span className="total">Status</span>
                          </div>
                          {ProductDiv}
                        </div>
                        <div className="payment-info">
                          <h3 className="info-title">Payment Information</h3>
                          <div className="payment-block">
                            <span>Order subtotal</span>
                            <span id="order-subtotal-value" className="has-price">
                              {orders.subtotal}
                            </span>
                          </div>
                          <div className="payment-block">
                            <span>Order tax</span>
                            <span id="order-tax-value" className="has-price">
                              {orders.tax}
                            </span>
                          </div>
                          <div className="payment-block">
                            <span>Freight Charge</span>
                            <span id="order-tax-value" className="has-price">
                              {orders.freight_charge}
                            </span>
                          </div>
                          <div className="payment-block">
                            <span className="is-bold">Order total</span>
                            <span id="order-grandtotal-value" className="has-price is-bold">
                              {orders.final_total}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="column is-4">
                        {/*Shipping Address*/}
                        <div className="address-widget is-narrow">
                          <h3 className="widget-title">Shipping Address</h3>
                          <p className="address">
                            <var id="shipping-address1">{orders.user_order_address.address1}</var>{" "}
                            <var id="shipping-address2">,{orders.user_order_address.address2}</var>
                            {/* <br />
                            <var id="shipping-city">Long Beach</var>, <var id="shipping-state">CA</var>{" "}
                            <var id="shipping-postalCode">90808-1702</var>
                            <br />
                            <var id="shipping-country">United States</var> */}
                          </p>
                        </div>
                        {/*Billing Address*/}
                        <div className="address-widget">
                          <h3 className="widget-title">Billing Address</h3>
                          <p className="address">
                            <var id="shipping-address1">{orders.user_order_address.address1}</var>{" "}
                            <var id="shipping-address2">,{orders.user_order_address.address2}</var>
                            {/* <br />
                            <var id="billing-city">Long Beach</var>, <var id="billing-state">CA</var>{" "}
                            <var id="billing-postalCode">90808-1702</var>
                            <br />
                            <var id="billing-country">United States</var> */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default userPanelOrderDetails;
