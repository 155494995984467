import React, { Component } from "react";
import { Link } from "react-router-dom";

class About extends Component {
  render() {
    return (
      <>
        <div className="page-content bg-white">
          <div className="content-block">
            <div className="section-full content-inner bg-white">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-6 col-md-12 m-b30">
                    <div className="our-story">
                      <span
                        style={{
                          color: "#163883",
                        }}
                      >
                        OUR STORY
                      </span>
                      <h2 className="title">
                        Running a <br />
                        successful business <br />
                        <span className="text-primary">since 1992</span>
                      </h2>
                      <p>
                        Neelkanth Fabricator was established in the year 1992, with an aim of providing the best Tankers and Trailers to
                        our customers. Since its inception, it has been providing an extensive assortment of tankers and trailers, committed
                        to satisfying the needs of the customers both locally and internationally.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 m-b30 our-story-thum">
                    <img src={require("../../images/about/01_OUR STORY-min.JPG")} className="radius-sm" alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="section-full box-about-list">
              <div className="row spno">
                <div className="col-lg-6 col-md-12">
                  <img src={require("../../images/about/02_Integrity-min.JPG")} alt="" className="img-cover" />
                </div>
                <div className="col-lg-6 col-md-12 bg-primary">
                  <div className="max-w700 m-auto p-tb50 p-lr20">
                    <div className="text-white">
                      <h1>
                        Integrity <br />
                      </h1>
                      <h3>
                        We present our capabilities honestly. We only make promises we can keep. We build trust by delivering exactly
                        according to the promises we have made.
                      </h3>
                      <h3>
                        <b>We then introduce our 5F Services –</b>
                      </h3>
                    </div>
                    <div className="icon-bx-wraper m-b30 left">
                      <div className="icon-md">
                        <Link to={"#"} className="icon-cell text-white">
                          <i className="flaticon-factory" />
                        </Link>
                      </div>
                      <div className="icon-content">
                        <h4>&#8203;</h4>
                        <h4 className="dlab-tilte">First Priority ( Customer Ideas & Needs)</h4>
                      </div>
                    </div>
                    <div className="icon-bx-wraper m-b30 left">
                      <div className="icon-md">
                        <Link to={"#"} className="icon-cell text-white">
                          <i className="flaticon-factory" />
                        </Link>
                      </div>
                      <div className="icon-content">
                        <h4>&#8203;</h4>
                        <h4 className="dlab-tilte">First Grade Quality</h4>
                      </div>
                    </div>
                    <div className="icon-bx-wraper m-b30 left">
                      <div className="icon-md">
                        <Link to={"#"} className="icon-cell text-white">
                          <i className="flaticon-factory" />
                        </Link>
                      </div>
                      <div className="icon-content">
                        <h4>&#8203;</h4>
                        <h4 className="dlab-tilte">Fast Service</h4>
                      </div>
                    </div>
                    <div className="icon-bx-wraper m-b30 left">
                      <div className="icon-md">
                        <Link to={"#"} className="icon-cell text-white">
                          <i className="flaticon-factory"></i>
                        </Link>
                      </div>
                      <div className="icon-content">
                        <h4>&#8203;</h4>
                        <h4 className="dlab-tilte">Fast Performance</h4>
                      </div>
                    </div>
                    <div className="icon-bx-wraper m-b30 left">
                      <div className="icon-md">
                        <Link to={"#"} className="icon-cell text-white">
                          <i className="flaticon-factory"></i>
                        </Link>
                      </div>
                      <div className="icon-content">
                        <h4>&#8203;</h4>
                        <h4 className="dlab-tilte">Fast Interchangeability on Tankers and Trailers</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="section-full content-inner const-about">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-12 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.3s">
                    <div className="radius-sm m-b30 img-ho1">
                      <img src={require("../../images/about/03_Accountable-min.jpeg")} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-12 wow fadeInRight" data-wow-duration="2s" data-wow-delay="0.6s">
                    <div className="content-bx1">
                      <div className="about-year">
                        <span>28</span>
                        <p>
                          Years
                          <br />
                          Experience
                          <br />
                          Working
                        </p>
                      </div>
                      <div className="section-head style2">
                        <h2 className="title">Accountable</h2>
                        <p>
                          We say what we mean, we mean what we say. Our actions never fail to follow our words. We hold ourselves to account
                          so that you don’t have to.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="section-full bg-gray content-inner">
              <div className="container">
                <div className="section-head text-center">
                  <h2 className="title">Why Choose Us?</h2>
                  {/* <p>
                    We say what we mean, we mean what we say. Our actions never fail to follow our words. We hold ourselves to account so
                    that you don’t have to.
                  </p> */}
                </div>
                <div className="section-content row">
                  <div className="col-xl-4 col-md-6 col-sm-12 service-box style3">
                    <div className="icon-bx-wraper" data-name="01">
                      <div className="icon-lg">
                        <i className="flaticon-robot-arm"></i>
                      </div>
                      <div className="icon-content">
                        <h2 className="dlab-tilte">Committed to Quality</h2>
                        <p>
                          To achieve all these, we ensure that all our products are of the highest quality. By combining the best of the
                          bests around the world, we are able to offer the greatest quality to our customers. The right mix of Quality and
                          Price is what we offer to our customers. &#8203; &#8203; &#8203; &#8203; &#8203; &#8203; &#8203; &#8203;&#8203;
                          &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                          &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203;
                          &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                          &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203;
                          &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                          &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203;
                          &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                          &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203;
                          &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                          &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203;
                          &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-md-6 col-sm-12 service-box style3">
                    <div className="icon-bx-wraper" data-name="02">
                      <div className="icon-lg">
                        <i className="flaticon-factory-1"></i>
                      </div>
                      <div className="icon-content">
                        <h2 className="dlab-tilte">Passion for Precision</h2>
                        <p>
                          When you will for something and somewhere deep down know it is not possible that’s when we bring our charms into
                          actions and do everything possible to get it done with utmost exactness. We're just as passionate about making
                          promises stick, so we don’t make any promises that we can't keep
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-md-6 col-sm-12 service-box style3">
                    <div className="icon-bx-wraper" data-name="03">
                      <div className="icon-lg">
                        <i className="flaticon-fuel-station"></i>
                      </div>
                      <div className="icon-content">
                        <h2 className="dlab-tilte">Strong Workforce</h2>
                        <p>
                          The size of the workforce that we as a fabricator have on hand is too large. Having such a large workforce,
                          enables us to be punctual enough to deliver our projects on time. &#8203; &#8203; &#8203; &#8203; &#8203; &#8203;
                          &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                          &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203;
                          &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                          &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203;
                          &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                          &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203;
                          &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                          &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203;
                          &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                          &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203;
                          &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                          &#8203;&#8203; &#8203; &#8203; &#8203; &#8203; &#8203; &#8203; &#8203; &#8203; &#8203; &#8203;&#8203; &#8203;
                          &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                          &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203;
                          &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                          &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203;
                          &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                          &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203;
                          &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                          &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203;
                          &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                          &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203;
                          &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-md-6 col-sm-12 service-box style3">
                    <div className="icon-bx-wraper" data-name="04">
                      <div className="icon-lg">
                        <i className="flaticon-fuel-truck" />
                      </div>
                      <div className="icon-content">
                        <h2 className="dlab-tilte">Deeply Customization</h2>
                        <p>
                          The customization program is available for both the tankers and trailers, giving customers the chance to create
                          their own personal tanker and trailers making it extra special and meeting any wishes with a personal touch
                          &#8203; &#8203; &#8203; &#8203; &#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203;
                          &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                          &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203;
                          &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                          &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203;
                          &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                          &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203;
                          &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                          &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203;
                          &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                          &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203;
                          &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-md-6 col-sm-12 service-box style3">
                    <div className="icon-bx-wraper" data-name="05">
                      <div className="icon-lg">
                        <i className="flaticon-conveyor-1"></i>
                      </div>
                      <div className="icon-content">
                        <h2 className="dlab-tilte">Clients Come First</h2>
                        <p>
                          We maintain specialized ideas for our clients. We work according to their needs and demands, and try to fulfill
                          all their needs and even their wants. Our team always find ways to come up with something special and better than
                          what’s to be expected.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-md-6 col-sm-12 service-box style3">
                    <div className="icon-bx-wraper" data-name="06">
                      <div className="icon-lg">
                        <i className="flaticon-engineer-1"></i>
                      </div>
                      <div className="icon-content">
                        <h2 className="dlab-tilte">Integrity</h2>
                        <p>
                          We present our capabilities honestly. We only make promises we can keep. We build trust by delivering exactly
                          according to the promises we have made. &#8203; &#8203; &#8203; &#8203; &#8203; &#8203; &#8203; &#8203;&#8203;
                          &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                          &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203;
                          &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                          &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203;
                          &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                          &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203;
                          &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                          &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203;
                          &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                          &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203;
                          &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                          &#8203; &#8203; &#8203; &#8203; &#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203;
                          &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                          &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203;
                          &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                          &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203;
                          &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                          &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203;
                          &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                          &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203;
                          &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                          &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203;
                          &#8203; &#8203; &#8203;&#8203; &#8203; &#8203; &#8203;&#8203; &#8203; &#8203;
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="section-full about-progress">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-7 col-md-12 bg-white">
                    <div className="max-w700  m-auto content-inner">
                      <div className="section-head">
                        <h2 className="title">We provide the exceptional service we'd want to experience ourselves</h2>
                        <p>
                          One of the renowned and committed fabricators in India was founded by Jerambhai N Sorathia in 1992. Over the
                          years, Neelkanth Fabricator emerged as one of the prominent manufacturer and supplier of exclusive array of
                          industrial trailers and tankers.
                        </p>
                        <p>
                          Our range of products is acknowledged for its reliability, long lasting life, compact size, dimensional accuracy
                          and high tensile strength.
                        </p>
                        <p>
                          Our growth has been driven by manufacturing quality products and finding practical and innovative solutions that
                          are valued by our customers. We roll out around 80 – 100 commercial vehicles per month and have a wide range of
                          products including –
                        </p>
                      </div>
                      <div className="about-progress-box">
                        <h2 style={{ fontSize: "19px" }} className="text-primary">
                          Tankers – Edible oil tank, Petroleum tank (HP,BPCL,IOCL)<span className="progress-num pull-right"></span>
                        </h2>
                        <div className="about-progress-bar bg-gray-dark">
                          <div
                            className="about-progress bg-primary wow slideInLeft"
                            data-wow-delay="0.2s"
                            data-wow-duration="2s"
                            style={{ width: "100%" }}
                          ></div>
                        </div>
                      </div>
                      <div className="about-progress-box">
                        <h2 style={{ fontSize: "19px" }} className="text-primary">
                          Tip Trailers<span className="progress-num pull-right"></span>
                        </h2>
                        <div className="about-progress-bar bg-gray-dark">
                          <div
                            className="about-progress bg-primary wow slideInLeft"
                            data-wow-delay="0.4s"
                            data-wow-duration="2s"
                            style={{ width: "100%" }}
                          ></div>
                        </div>
                      </div>
                      <div className="about-progress-box">
                        <h2 style={{ fontSize: "19px" }} className="text-primary">
                          Flatbed Trailers<span className="progress-num pull-right"></span>
                        </h2>
                        <div className="about-progress-bar bg-gray-dark">
                          <div
                            className="about-progress bg-primary wow slideInLeft"
                            data-wow-delay="0.6s"
                            data-wow-duration="2s"
                            style={{ width: "100%" }}
                          ></div>
                        </div>
                      </div>
                      <div className="about-progress-box">
                        <h2 style={{ fontSize: "19px" }} className="text-primary">
                          Skeleton Trailers<span className="progress-num pull-right"></span>
                        </h2>
                        <div className="about-progress-bar bg-gray-dark">
                          <div
                            className="about-progress bg-primary wow slideInLeft"
                            data-wow-delay="0.6s"
                            data-wow-duration="2s"
                            style={{ width: "100%" }}
                          ></div>
                        </div>
                      </div>
                      <div className="about-progress-box">
                        <h2 style={{ fontSize: "19px" }} className="text-primary">
                          Side Wall Trailers<span className="progress-num pull-right"></span>
                        </h2>
                        <div className="about-progress-bar bg-gray-dark">
                          <div
                            className="about-progress bg-primary wow slideInLeft"
                            data-wow-delay="0.6s"
                            data-wow-duration="2s"
                            style={{ width: "100%" }}
                          ></div>
                        </div>
                      </div>
                      <div className="about-progress-box">
                        <h2 style={{ fontSize: "19px" }} className="text-primary">
                          Milk Tankers<span className="progress-num pull-right"></span>
                        </h2>
                        <div className="about-progress-bar bg-gray-dark">
                          <div
                            className="about-progress bg-primary wow slideInLeft"
                            data-wow-delay="0.6s"
                            data-wow-duration="2s"
                            style={{ width: "100%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-12 p-a0">
                    <img src={require("../../images/about/about-us-min.jpg")} alt="" className="img-cover " />
                  </div>
                </div>
              </div>
            </div>
            <div className="section-full call-action bg-primary wow fadeIn" data-wow-duration="2s" data-wow-delay="0.3s">
              <div className="container">
                <div className="row">
                  <div className="col-lg-9 text-white">
                    <h2 className="title">Contact Us Now</h2>
                    <p className="m-b0">Our diligent team of professionals understands your requirements and acts accordingly.</p>
                  </div>
                  <div className="col-lg-3 d-flex">
                    <Link to="/contact-us" className="site-button btnhover13 white align-self-center outline ml-auto outline-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default About;
