import React, { Component } from "react";
import { Link } from "react-router-dom";

const bg = require("../../images/banner/bnr1.jpg");

class PrivacyPolicy extends Component {
  render() {
    return (
      <>
        <div className="page-content bg-white">
          <div className="content-block">
            <div className="section-full content-inner inner-text">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="dlab-divider bg-gray-dark"></div>
                    <h4 className="title">Returns</h4>
                    <ul className="list-circle primary m-a0">
                      <li>
                        Our policy lasts for 30 days. If 30 days have gone since your purchase, unfortunately we can’t offer you a refund or
                        exchange. To be eligible for a return, your item must be unused and in the same condition that you received it. It
                        must also be in the original packaging. Several types of goods are exempted from being returned. We also do not
                        accept products that are intimate or sanitary goods, hazardous materials, or flammable liquids or gases.
                      </li>
                      <li>
                        <span className="c1">Additional non-returnable items:</span>
                        <ul className="c7 lst-kix_xjp5nxxpd3x0-0 start">
                          <li className="c2 c8">
                            <span className="c1">Gift cards</span>
                          </li>
                          <li className="c2 c8">
                            <span className="c1">Downloadable software products</span>
                          </li>
                        </ul>
                      </li>
                      <li>To complete your return, we require a receipt or proof of purchase.</li>
                      <li>Please do not send your purchase back to the manufacturer.</li>
                      <li>
                        There are certain situations where only partial refunds are granted: (if applicable) Book with obvious signs of use
                        CD, DVD, VHS tape, software, video game, cassette tape, or vinyl record that has been opened. Any item not in its
                        original condition, is damaged or missing parts for reasons not due to our error. Any item that is returned more
                        than 30 days after delivery
                      </li>
                    </ul>
                    <div className="dlab-divider bg-gray-dark"></div>
                    <h4 className="title">Refunds (if applicable)</h4>
                    <ul className="list-circle primary m-a0">
                      <li>
                        Once your return is received and inspected, we will send you an email to notify you that we have received your
                        returned item. We will also notify you of the approval or rejection of your refund. If you are approved, then your
                        refund will be processed, and a credit will automatically be applied to your credit card or original method of
                        payment, within a certain amount of days.
                      </li>
                    </ul>
                    <div className="dlab-divider bg-gray-dark"></div>
                    <h4 className="title">Late or missing refunds (if applicable)</h4>
                    <ul className="list-circle primary m-a0">
                      <li>
                        If you haven’t received a refund yet, first check your bank account again. Then contact@ your credit card company, it
                        may take some time before your refund is officially posted. Next contact your bank. There is often some processing
                        time before a refund is posted. If you’ve done all of this and you still have not received your refund yet, please
                        contact us at info@neelkanthfabricators.com
                      </li>
                    </ul>
                    <div className="dlab-divider bg-gray-dark"></div>
                    <h4 className="title">Sale items (if applicable)</h4>
                    <ul className="list-circle primary m-a0">
                      <li>Only regular priced items may be refunded, unfortunately sale items cannot be refunded.</li>
                    </ul>
                    <div className="dlab-divider bg-gray-dark"></div>
                    <h4 className="title">Exchanges (if applicable)</h4>
                    <ul className="list-circle primary m-a0">
                      <li>
                        We only replace items if they are defective or damaged. If you need to exchange it for the same item, send us an
                        email at info@neelkanthfabricators.com and send your item to: Neelkanth Fabricator., Plot No.:
                        51-52-53, G.I.D.C., Anjar. Kutch, [Gujarat]
                      </li>
                    </ul>
                    <div className="dlab-divider bg-gray-dark"></div>
                    <h4 className="title">Gifts</h4>
                    <ul className="list-circle primary m-a0">
                      <li>
                        If the item was marked as a gift while purchased and shipped directly to you, you’ll receive a gift credit for the
                        value of your return. Once the returned item is received, a gift certificate will be mailed to you. If the item
                        wasn’t marked as a gift when purchased, or the gift giver had the order shipped to themselves to give to you later,
                        we will send a refund to the gift giver and he will find out about your return.
                      </li>
                    </ul>
                    <div className="dlab-divider bg-gray-dark"></div>
                    <h4 className="title">Shipping</h4>
                    <ul className="list-circle primary m-a0">
                      <li>
                        To return your product, you should mail your product to: Neelkanth Fabricator., Plot No.: 51-52-53,
                        G.I.D.C., Anjar. Kutch, [Gujarat]. You will be responsible for paying for your own shipping costs for returning your
                        item. Shipping costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from
                        your refund. Depending on where you live, the time it may take for your exchanged product to reach you, may vary. If
                        you are shipping an item over 1000, you should consider using a traceable shipping service or purchasing shipping
                        insurance. We don’t guarantee that we will receive your returned item.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default PrivacyPolicy;
