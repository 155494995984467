import React, { Component } from "react";
import { Link } from "react-router-dom";

class Footer extends Component {
  render() {
    return (
      <>
        <footer className="site-footer footer-gray-1">
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-3 col-md-6 col-sm-6 wow fadeIn"
                  data-wow-delay="0.2s"
                >
                  <div className="widget border-0">
                    <h6 className="m-b20 text-white font-weight-300 text-uppercase">
                      Quick Links
                    </h6>
                    <ul className="list-2">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/primary-product">Primary Product</Link>
                      </li>
                      <li>
                        <Link to="/about-us">About Us</Link>
                      </li>
                      <li>
                        <Link to="/contact-us">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-6 col-sm-6 wow fadeIn"
                  data-wow-delay="0.4s"
                >
                  <div className="widget">
                    <h6 className="m-b10 text-white font-weight-300 text-uppercase">
                      Connect with us
                    </h6>
                    <ul className="list-inline m-a0">
                      <li>
                        <a
                          target="_blank"
                          className="site-button btnhover13 facebook sharp"
                          href="https://www.facebook.com/NeelkanthFabricators/"
                        >
                          <i className="fa fa-facebook" />
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          className="site-button btnhover13 google-plus sharp"
                          href="https://t.me/neelkanthfabricators"
                        >
                          <i
                            className="fa fa-telegram fa-2x"
                            style={{ paddingTop: "7px" }}
                          />
                        </a>
                      </li>
                      {/* <li>
                        <Link to="#" className="site-button btnhover13 instagram sharp">
                          <i className="fa fa-instagram" />
                        </Link>
                      </li> */}
                      {/* <li>
                        <Link to="#" className="site-button btnhover13 twitter sharp">
                          <i className="fa fa-twitter" />
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-6 col-sm-6 wow fadeIn"
                  data-wow-delay="0.4s"
                >
                  <div className="widget">
                    <ul className="list-inline m-a0">
                      <li>
                        <img
                          style={{ height: "50px", weight: "50px" }}
                          src={require("../../images/iCAT-Logo.jpg")}
                          alt=""
                        />
                        &nbsp;&nbsp;&nbsp;
                        <img
                          style={{ height: "50px", weight: "50px" }}
                          src={require("../../images/PESO Logo.png")}
                          alt=""
                        />
                        &nbsp;&nbsp;&nbsp;
                        <img
                          style={{ height: "50px", weight: "50px" }}
                          src={require("../../images/ISO.png")}
                          alt=""
                        />
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-12 col-sm-12 wow fadeIn"
                  data-wow-delay="0.6s"
                >
                  <div className="widget border-0">
                    <h6 className="m-b20 text-white font-weight-300 text-uppercase">
                      Policies
                    </h6>
                    <ul className="list-2">
                      <li>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/terms-and-conditions">
                          Terms & Conditions
                        </Link>
                      </li>
                      <li>
                        <Link to="/refund-policy">
                          Cancellation/Refund Policy
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-6 text-left ">
                  {" "}
                  <span>
                    Copyright © 2020 Neelkanth Fabricator. all rights
                    reserved.
                  </span>{" "}
                </div>
                <div className="col-md-6 col-sm-6 text-right " />
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}
export default Footer;
