import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const content = [
  {
    image: require("../../images/portfolio/mining/Comited To Qulity.jpg"),
    icon: "flaticon-robot-arm",
    title: "Committed to Quality",
    description:
      "To achieve all these, we ensure that all our products are of the highest quality. By combining the best of the bests around the world, we are able to offer the greatest quality to our customers.",
  },
  {
    image: require("../../images/portfolio/mining/Passion For Precision.jpg"),
    icon: "flaticon-factory-1",
    title: "Passion for Precision",
    description:
      "When you will for something and somewhere deep down know it is not possible that’s when we bring our charms into actions and do everything possible to get it done with utmost exactness.",
  },
  {
    image: require("../../images/portfolio/mining/Strong Workforce.jpg"),
    icon: "flaticon-fuel-station",
    title: "Strong Workforce",
    description:
      "The size of the workforce that we as a fabricator have on hand is too large. Having such a large workforce, enables us to be punctual enough to deliver our projects on time.",
  },
  {
    image: require("../../images/portfolio/mining/Deeply Customization.jpg"),
    icon: "flaticon-fuel-truck",
    title: "Deeply Customization",
    description:
      "The customization program is available for both the tankers and trailers, giving customers the chance to create their own personal tanker and trailers making it extra special and meeting any wishes with a personal touch.",
  },
];

class ServicesSlider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      arrows: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: false,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <>
        <Slider {...settings} className="img-carousel service-box-4-area  m-b30">
          {content.map((item, id) => (
            <div className="item wow fadeInUp" key={id} data-wow-duration="2s" data-wow-delay="0.2s">
              <div className="service-box-4 text-center" key={id}>
                <div className="service-images m-b15">
                  <img style={{ height: "200px" }} src={item.image} alt="" />
                  <i className={item.icon}></i>
                </div>
                <div className="service-content">
                  <h6 className="title">
                    <Link to="/services-details">{item.title}</Link>
                  </h6>
                  <p>{item.description}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </>
    );
  }
}
export default ServicesSlider;
