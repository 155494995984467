import React, { Component } from "react";
import { Link } from "react-router-dom";
import "react-modal-video/scss/modal-video.scss";
import API from "../../Api";
import ScrollToTop from "../element/scrollToTop";
const loaderIMG = require("../../images/Loading_icon.gif");
class PrimaryProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      data: [],
    };
  }

  componentDidMount() {
    API.get(`products/special`).then((data) => {
      console.log(data);
      this.setState({ data: data });
    });
  }

  openModal = (event) => {
    event.preventDefault();
    this.setState({ isOpen: true });
  };

  closeModal = () => {
    this.setState({ isOpen: false });
  };

  render() {
    return (
      <>
        <div class="page-content bg-white">
          <div class="content-block">
            <div class="section-full content-inner bg-gray">
              <div class="container">
                <div class="section-head text-black text-center">
                  <h2 class="title">Primary product</h2>
                </div>
                <div class="row">
                  {this.state.data.length === 0 ? (
                    <img src={loaderIMG} alt="" className="LoaderCSS" />
                  ) : (
                    this.state.data.map((product) => (
                      <div class="col-lg-4 col-md-6 col-sm-12 m-b50 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.3s">
                        <Link to={`/product/${product.product_id}`}>
                          <div class="dlab-box service-box-3">
                            <Link to={`/product/${product.product_id}`}>
                              <div class="dlab-media radius-sm dlab-img-overlay1">
                                <Link to={`/product/${product.product_id}`}>
                                  <img height="417" width="298" src={product.images[0].url} alt="" />
                                </Link>
                              </div>
                            </Link>
                            <div class="dlab-info">
                              <h4 class="title">
                                <Link to={`/product/${product.product_id}`}>{product.name}</Link>
                              </h4>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <ScrollToTop className="style1 radius" />
      </>
    );
  }
}
export default PrimaryProduct;
