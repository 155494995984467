import React, { Component } from "react";
import ImageGallery from "react-image-gallery";
class ProductGallerySlider extends Component {
  render() {
    console.log(this.props.images);
    let outputthumbnail = this.props.images.map((s) => ({ original: s.url, thumbnail: s.url }));
    console.log(outputthumbnail);
    return (
      <>
        {/* <div class="item">
          <div class="mfp-gallery">
            <div class="dlab-box"> */}
        <ImageGallery items={outputthumbnail} />
        {/* </div>
          </div>
        </div> */}
      </>
    );
  }
}
export default ProductGallerySlider;
