const helper = {
  checkNullAndEmpty: function (variable) {
    return variable != null && variable != undefined && variable != "" && variable != "undefined";
  },
  checkNull: function (variable) {
    return variable != null && variable != undefined;
  },
  checkEmpty: function (variable) {
    return variable != null && variable != undefined;
  },
  isEmptyObject: function (obj) {
    return Object.keys(obj).length === 0;
  },
};
export default helper;
