import React, { Component } from "react";
import { Link } from "react-router-dom";
import ProductGallerySlider from "../element/productGallerySlider";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import API from "../../Api";
import { addToCart } from "../../actions/cartActions";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const loaderIMG = require("../../images/Loading_icon.gif");
const notifys = () => toast("Product Added To cart !", { position: "bottom-right" });
const notifyssss = () => toast("We will get back to you Soon !", { position: "bottom-right" });
class productDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      name: "",
      mobile_number: "",
      email_id: "",
      subject: "",
      message: "",
      type: "Quote",
      product_id: this.props.match.params.productId,
    };
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onSubmit = async (e) => {
    e.preventDefault();
    const { name, mobile_number, email_id, subject, message, type, product_id } = this.state;
    let dataToSend = {
      name: name,
      mobile_number: mobile_number,
      email_id: email_id,
      subject: subject,
      message: message,
      type: type,
      product_id: product_id,
    };
    API.post("common/saveContactUs", dataToSend)
      .then((res) => {
        notifyssss();
        console.log(res);
        this.setState({
          name: "",
          mobile_number: "",
          email_id: "",
          subject: "",
          message: "",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  componentDidMount() {
    let product_id = this.props.match.params.productId;
    API.get(`products/get/${product_id}`)
      .then((data) => {
        console.log(data);
        this.setState({ data: data });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  handleCartClick = (id, data) => {
    console.log(id);
    this.props.addToCart(id, data);
    notifys();
  };
  render() {
    const { name, mobile_number, email_id, subject, message } = this.state;
    return (
      <>
        {Object.keys(this.state.data).length === 0 ? (
          <img src={loaderIMG} alt="" className="LoaderCSS" />
        ) : (
          <div class="page-content bg-white">
            <ToastContainer />
            <div class="section-full content-inner bg-white">
              <div class="container woo-entry">
                <div class="row m-b30">
                  <div class="col-md-5 col-lg-5 col-sm-12 m-b30">
                    <ProductGallerySlider images={this.state.data.images} />
                  </div>
                  <div class="col-md-7 col-lg-7 col-sm-12">
                    <form method="post" class="cart sticky-top">
                      <div class="dlab-post-title">
                        <h4 class="post-title">
                          <Link to="#">{this.state.data.name}</Link>
                        </h4>
                        <p class="m-b10">{this.state.data.short_description}</p>
                        <div class="dlab-divider bg-gray tb15">
                          <i class="icon-dot c-square"></i>
                        </div>
                      </div>
                      <div class="relative">{this.state.data.is_special ? null : <h3 class="m-tb10">₹ {this.state.data.price}</h3>}</div>
                      <div class="dlab-divider bg-gray tb15">
                        <i class="icon-dot c-square"></i>
                      </div>
                      <div>
                        <form class="inquiry-form wow fadeInUp" data-wow-delay="0.2s" onSubmit={this.onSubmit}>
                          <h3 class="title-box font-weight-300 m-t0 m-b10">
                            Get Your Quote Now! <span class="bg-primary"></span>
                          </h3>
                          <div class="row">
                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <div class="input-group">
                                  <span class="input-group-addon">
                                    <i class="ti-user text-primary"></i>
                                  </span>
                                  <input
                                    name="name"
                                    type="text"
                                    required
                                    class="form-control"
                                    placeholder="Name"
                                    value={name}
                                    onChange={this.onChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <div class="input-group">
                                  <span class="input-group-addon">
                                    <i class="ti-mobile text-primary"></i>
                                  </span>
                                  <input
                                    name="mobile_number"
                                    type="number"
                                    required
                                    class="form-control"
                                    placeholder="Phone"
                                    value={mobile_number}
                                    onChange={this.onChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                              <div class="form-group">
                                <div class="input-group">
                                  <span class="input-group-addon">
                                    <i class="ti-email text-primary"></i>
                                  </span>
                                  <input
                                    name="email_id"
                                    type="email"
                                    class="form-control"
                                    required
                                    placeholder="Your Email Id"
                                    value={email_id}
                                    onChange={this.onChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                              <div class="form-group">
                                <div class="input-group">
                                  <span class="input-group-addon">
                                    <i class="ti-email text-primary"></i>
                                  </span>
                                  <input
                                    name="subject"
                                    type="text"
                                    class="form-control"
                                    required
                                    placeholder="Your Query"
                                    value={subject}
                                    onChange={this.onChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                              <div class="form-group">
                                <div class="input-group">
                                  <span class="input-group-addon">
                                    <i class="ti-agenda text-primary"></i>
                                  </span>
                                  <textarea
                                    name="message"
                                    rows="4"
                                    class="form-control"
                                    required
                                    placeholder="Tell Us more about what you want!"
                                    value={message}
                                    onChange={this.onChange}
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                              {this.state.data.is_quotation ? null : (
                                <Link
                                  to="#"
                                  onClick={() => {
                                    this.handleCartClick(this.state.data.product_id, this.state.data);
                                  }}
                                >
                                  <button name="submit" type="submit" value="Submit" class="site-button button-md">
                                    {" "}
                                    <span>Add To Cart</span>{" "}
                                  </button>
                                </Link>
                              )}
                              {this.state.data.is_quotation ? null : <span> &nbsp;&nbsp; Or &nbsp;&nbsp;</span>}

                              <button name="submit" type="submit" value="Submit" class="site-button button-md">
                                {" "}
                                <span>Contact Us</span>{" "}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="row m-b30">
                  <div class="col-lg-12">
                    <div class="dlab-tabs  product-description tabs-site-button">
                      <h1
                        style={{
                          margin: `15px 0px 5px 0px`,
                          borderBottom: `2px solid #163883`,
                          fontSize: `28px`,
                        }}
                      >
                        Description
                      </h1>
                      <p dangerouslySetInnerHTML={{ __html: this.state.data.long_description }}></p>

                      {/* <Tabs>
                        <TabList className="nav nav-tabs ">
                          <Tab selectedClassName="tab-active">
                            <Link to="#">
                              <i class="fa fa-globe"></i> Description
                            </Link>
                          </Tab>
                        </TabList>

                        <TabPanel class="tab-pane " dangerouslySetInnerHTML={{ __html: this.state.data.long_description }}></TabPanel>
                      </Tabs> */}
                    </div>
                  </div>
                </div>
                {/* <div class="row">
                    <div class="col-lg-12">
                      <h5 class="m-b20">Related Products</h5>
                      <ProductSlider sub_cat={this.state.data.product_sub_category_id}/>
                    </div>
                  </div> */}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.items,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (id, data) => {
      dispatch(addToCart(id, data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(productDetails);
// export default productDetails;
