import React, { Component } from "react";
import { Link } from "react-router-dom";
import { removeItem, addQuantity, subtractQuantity } from "../../actions/cartActions";
import { connect } from "react-redux";
import API from "../../Api";
import commonHelper from "./../../helper";

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      localStorageCart: [],
      is_address: false,
      is_gujarat: true,
      cart_order_sub_total: 0,
      freight_charge: 0,
      totalWeight_products: 0,
      address_pin_code: 0,
      user_id: "",
    };
  }
  //to remove the item completely
  handleRemove = (id, data) => {
    this.cal_freight_charge();
    this.props.removeItem(id, data);
    this.setState({ localStorageCart: JSON.parse(localStorage.getItem("cart")) });
  };
  //to add the quantity
  handleAddQuantity = (id, data) => {
    this.cal_freight_charge();
    this.props.addQuantity(id, data);
    this.setState({ localStorageCart: JSON.parse(localStorage.getItem("cart")) });
  };
  //to substruct from the quantity
  handleSubtractQuantity = (id, data) => {
    this.cal_freight_charge();
    this.props.subtractQuantity(id, data);
    this.setState({ localStorageCart: JSON.parse(localStorage.getItem("cart")) });
  };
  componentDidMount() {
    this.setState({ localStorageCart: JSON.parse(localStorage.getItem("cart")) });
    if (commonHelper.checkNullAndEmpty(localStorage.getItem("user_id"))) {
      this.setState({ user_id: localStorage.getItem("user_id") });

      API.get(`user/user-info/${localStorage.getItem("user_id")}`).then((data) => {
        console.log(data);
        if (data.address) {
          this.setState({ address_pin_code: data.address.pin_code });
          this.setState({ is_address: true });
          let stateID = data.address.state;
          API.get(`common/getStates/${stateID}`)
            .then((res) => {
              if (res) {
                let StateName = res[0].name;
                if (StateName == "Gujarat") {
                  this.setState({ is_gujarat: true });
                } else {
                  this.setState({ is_gujarat: false });
                }
              }
            })
            .then(() => {
              this.cal_freight_charge();
            });
        }
      });
    } else {
    }
  }
  cal_freight_charge() {
    const { localStorageCart, freight_charge, totalWeight_products, user_id } = this.state;
    let is_cart_empty = false;
    if (commonHelper.checkNullAndEmpty(localStorageCart)) {
      let totalWeight = 1;

      let total =
        localStorageCart.length !== 0
          ? localStorageCart.products.map((item) => {
              totalWeight += item.weight * item.quantity;
            })
          : null;
      console.log("totalWeighttotalWeight", totalWeight);
      // if (Data > 0) {
      API.get(`common/check-courier-serviceability/${this.state.address_pin_code}/${totalWeight}/0`).then((res) => {
        if (res) {
          if (res.status == 404) {
            this.setState({ freight_charge: 0 });
          } else {
            this.setState({ freight_charge: res.data });
          }

          console.log("resresres", res.status);
        }
      });
      // }
    }
  }
  render() {
    const { localStorageCart, is_address, is_gujarat, freight_charge } = this.state;
    let cart_order_sub_total = 0;
    let taxTotal = 0;
    let totalWeight = 0;
    let vatotal = 0;
    let localStorageVariable = [];
    let is_cart_empty = false;
    if (commonHelper.checkNullAndEmpty(localStorageCart)) {
      localStorageVariable = localStorageCart;
      is_cart_empty = true;
      vatotal =
        localStorageVariable.length !== 0
          ? localStorageVariable.products.map((item) => {
              let tax = 0;
              //console.log(item);
              if (is_gujarat) {
                tax = item.Sgst + item.Cgst;
              } else {
                tax = item.Igst;
              }
              console.log("taxtax", tax);
              taxTotal += ((item.price * item.quantity) / 100) * tax;
              cart_order_sub_total += item.price * item.quantity;
              totalWeight += item.weight * item.quantity;
            })
          : null;
    } else {
      console.log("empty");
    }
    return (
      <>
        <div className="page-content bg-white">
          <div className="section-full content-inner">
            <div className="container">
              {/* <Loading loading background="#163883" loaderColor="#ffffff" /> */}
              <div className="row">
                <div className="col-lg-12">
                  <div className="table-responsive">
                    <table className="table check-tbl">
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Product name</th>
                          <th>Unit Price</th>
                          <th>Quantity</th>
                          <th>Total</th>
                          <th>Remove</th>
                        </tr>
                      </thead>
                      <tbody>
                        {localStorageVariable.length !== 0 ? (
                          localStorageVariable.products.map((item) => {
                            return (
                              <tr className="alert">
                                <td className="product-item-img">
                                  <img src={item.images[0].url} alt="" />
                                </td>
                                <td className="product-item-name">{item.name}</td>
                                <td className="product-item-price">₹ {item.price}</td>
                                <td className="product-item-quantity">
                                  <div className="quantity btn-quantity max-w80">
                                    <Link to="/cart">
                                      <i
                                        className="fa fa-plus"
                                        onClick={() => {
                                          this.handleAddQuantity(item.product_id, item);
                                        }}
                                      />
                                    </Link>
                                    &nbsp;&nbsp;&nbsp;
                                    <span>{item.quantity}</span>
                                    &nbsp;&nbsp;&nbsp;
                                    <Link to="/cart">
                                      <i
                                        className="fa fa-minus"
                                        onClick={() => {
                                          this.handleSubtractQuantity(item.product_id, item);
                                        }}
                                      />
                                    </Link>
                                  </div>
                                </td>
                                <td className="product-item-totle">₹ {item.quantity * item.price}</td>
                                <td className="product-item-close">
                                  <Link
                                    to="#"
                                    data-dismiss="alert"
                                    onClick={() => {
                                      this.handleRemove(item.product_id, item);
                                    }}
                                    aria-label="close"
                                    className="ti-close"
                                  />
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr className="alert">
                            <td className="product-item-img">cart is empty</td>
                            <td className="product-item-name"> cart is empty </td>
                            <td className="product-item-price"> cart is empty</td>
                            <td className="product-item-quantity">cart is empty</td>
                            <td className="product-item-totle"> cart is empty </td>
                            <td className="product-item-close">cart is empty</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {is_cart_empty ? (
                <div className="row">
                  <div className="col-lg-6 m-b15" />
                  <div className="col-lg-6 m-b15">
                    <h5>Cart Subtotal</h5>
                    <table className="table-bordered check-tbl">
                      <tbody>
                        <tr>
                          <td>Order Subtotal</td>
                          <td>₹ {cart_order_sub_total}</td>
                        </tr>
                        <tr>
                          <td>Tax</td>
                          <td>₹ {taxTotal}</td>
                        </tr>
                        <tr>
                          <td>Freight Charges</td>
                          <td>
                            ₹ &nbsp;
                            {freight_charge}
                          </td>
                        </tr>
                        <tr>
                          <td>Total</td>
                          <td>
                            ₹ &nbsp;
                            {(freight_charge + cart_order_sub_total + taxTotal).toFixed(2)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="form-group">
                      {is_address ? (
                        <Link to="/checkout" className="site-button" type="button">
                          Proceed to Checkout
                        </Link>
                      ) : (
                        <Link to="/login" className="site-button" type="button">
                          Please Add Address!
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </>
    );
  }
}
// export default Cart;

const mapStateToProps = (state) => {
  return {
    items: JSON.parse(localStorage.getItem("cart")),
    //addedItems: state.addedItems
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    removeItem: (id, data) => {
      dispatch(removeItem(id, data));
    },
    addQuantity: (id, data) => {
      dispatch(addQuantity(id, data));
    },
    subtractQuantity: (id, data) => {
      dispatch(subtractQuantity(id, data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Cart);
