import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import API from "../../Api";
const bg = require("../../images/banner/bnr5.jpg");

class Register extends Component {
  componentDidMount() {
    if (localStorage.getItem("name")) {
      this.props.history.push(`/`);
    }
  }
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      first_name_error: false,
      last_name: "",
      last_name_error: "",
      email: "",
      password: "",
      errorMessage: "",
    };
  }
  onChange = (e) => {
    /*
          Because we named the inputs to match their
          corresponding values in state, it's
          super easy to update the state
        */

    this.setState({ [e.target.name]: e.target.value });
  };
  onSubmit = async (e) => {
    e.preventDefault();
    const { first_name, last_name, email, password } = this.state;

    if (first_name) {
      let regName = /^[a-zA-Z ]+$/;
      if (!regName.test(first_name)) {
        this.setState({ first_name_error: true });
        return;
      } else {
        this.setState({ first_name_error: false });
      }
    }
    if (last_name) {
      let regName = /^[a-zA-Z ]+$/;
      if (!regName.test(last_name)) {
        this.setState({ last_name_error: true });
        return;
      } else {
        this.setState({ last_name_error: false });
      }
    }
    let datatoSend = {
      name: `${first_name} ${last_name}`,
      email: email,
      password: password,
    };
    API.post("user/register", datatoSend)
      .then((data) => {
        localStorage.setItem("user_id", data.user_id);
        localStorage.setItem("token", data.token);
        localStorage.setItem("name", data.name);
        window.location.reload();
      })
      .catch((err) => {
        this.setState({ errorMessage: err.response.data.error.message });
      });
  };
  render() {
    const { first_name, last_name, email, password, first_name_error, last_name_error } = this.state;
    return (
      <>
        <div className="page-content bg-white">
          <div className="section-full content-inner shop-account">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <h2 className="font-weight-700 m-t0 m-b40">CREATE AN ACCOUNT</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 m-b30">
                  <div className="p-a30 border-1  max-w500 m-auto">
                    <div className="tab-content">
                      <form id="login" className="tab-pane active" onSubmit={this.onSubmit}>
                        <h4 className="font-weight-700">PERSONAL INFORMATION</h4>
                        <p className="font-weight-600">If you have an account with us, please log in.</p>
                        <div className="form-group">
                          <label className="font-weight-700">First Name *</label>
                          <input
                            name="first_name"
                            value={first_name}
                            onChange={this.onChange}
                            required
                            className="form-control"
                            placeholder="First Name"
                            type="text"
                          />
                          <br></br>
                          {first_name_error ? <span style={{ color: "red" }}>Please do not use numbers or special characters</span> : null}
                        </div>
                        <div className="form-group">
                          <label className="font-weight-700">Last Name *</label>
                          <input
                            name="last_name"
                            value={last_name}
                            onChange={this.onChange}
                            required
                            className="form-control"
                            placeholder="Last Name"
                            type="text"
                          />
                          <br></br>
                          {last_name_error ? <span style={{ color: "red" }}>Please do not use numbers or special characters</span> : null}
                        </div>
                        <div className="form-group">
                          <label className="font-weight-700">E-MAIL *</label>
                          <input
                            name="email"
                            value={email}
                            onChange={this.onChange}
                            required
                            className="form-control"
                            placeholder="Your Email Id"
                            type="email"
                          />
                        </div>
                        <div className="form-group">
                          <label className="font-weight-700">PASSWORD *</label>
                          <input
                            name="password"
                            value={password}
                            onChange={this.onChange}
                            required
                            className="form-control "
                            placeholder="Type Password"
                            type="password"
                          />
                        </div>

                        <div className="form-group">
                          {this.state.errorMessage && <span style={{ color: "red" }}> {this.state.errorMessage} </span>}
                        </div>
                        <div className="text-left">
                          <button type="submit" className="site-button m-r5 button-lg radius-no">
                            CREATE
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Register;
