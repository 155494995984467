import axios from "axios";

let baseURL = `https://api.neelkanthfabricators.com/api/`;
//let baseURL = `http://localhost:9002/api/`;
const config = {
  headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
};
const common = {
  Auth: function (url, data) {
    return new Promise(function (resolve, reject) {
      axios
        .put(`${baseURL}${url}`, data)
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  get: function (url) {
    return new Promise(function (resolve, reject) {
      axios
        .get(`${baseURL}${url}`, config)
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  post: function (url, data) {
    return new Promise(function (resolve, reject) {
      axios
        .post(`${baseURL}${url}`, data, config)
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default common;

// import axios from 'axios';
// export default class API {
//      baseURL = `http://localhost:9002/api/`;
//      static userAuth (URL, Params) {
//         return new Promise (function(resolve, reject) {
//             axios.put(`http://localhost:9002/api/${URL}`, Params).then(response => {
//               return resolve(response)
//             }).catch(err => {
//                 return  reject(err)
//             });
//         })
//     }
// }
// import axios from 'axios';
// class Service {
//     constructor() {
//         let service = axios.create({
//             headers: {csrf: 'token'}
//         });
//         service.interceptors.response.use(this.handleSuccess, this.handleError);
//         this.service = service;
//     }
//
//     handleSuccess(response) {
//         return response;
//     }
//
//     handleError = (error) => {
//         switch (error.response.status) {
//             case 401:
//                 //this.redirectTo(document, '/')
//                 break;
//             case 404:
//                 //this.redirectTo(document, '/404')
//                 break;
//             default:
//                 //this.redirectTo(document, '/500')
//                 break;
//         }
//         return Promise.reject(error)
//     }
//
//     redirectTo = (document, path) => {
//         document.location = path
//     }
//
//     get(path, callback) {
//         return this.service.get(path).then(
//             (response) => callback(response.status, response.data)
//         );
//     }
//
//     patch(path, payload, callback) {
//         return this.service.request({
//             method: 'PATCH',
//             url: path,
//             responseType: 'json',
//             data: payload
//         }).then((response) => callback(response.status, response.data));
//     }
//
//     post(path, payload, callback) {
//         return this.service.request({
//             method: 'POST',
//             url: path,
//             responseType: 'json',
//             data: payload
//         }).then((response) => callback(response.status, response.data));
//     }
//     put(path, payload, callback) {
//         return this.service.request({
//             method: 'PUT',
//             url: path,
//             responseType: 'json',
//             data: payload
//         }).then((response) => callback(response.status, response.data));
//     }
// }
//
// export default new Service;
