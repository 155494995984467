import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Table } from "reactstrap";
import API from "../../../Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import 'react-tabs/style/react-tabs.css';
import "../../../css/skin/testCheckout/main.css";

const notifys = () => toast("Profile Updated!", { position: "bottom-right" });
class userPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      states: [],
      cities: [],
      userInfo: {},
      edit: false,
      address_edit: false,
      name: "",
      email: "",
      phone: "",
      address_full_name: "",
      address_mobile_number: "",
      address_pin_code: "",
      address_address1: "",
      address_address2: "",
      address_landmark: "",
      address_city: "",
      address_country: "",
      address_state: "",
    };
    this.onStateChange = this.onStateChange.bind(this);
  }
  onChange = (e) => {
    /*
          Because we named the inputs to match their
          corresponding values in state, it's
          super easy to update the state
        */
    console.log(e.target.name, e.target.value);
    this.setState({ [e.target.name]: e.target.value });
  };
  EditProfile() {
    this.setState({ edit: true });
  }
  EditAddress() {
    this.setState({ address_edit: true });
  }

  onSubmit = async (e) => {
    e.preventDefault();
    let { name, email, phone } = this.state;
    API.Auth("user/update-profile", { user_id: localStorage.getItem("user_id"), email: email, name: name, phone: phone })
      .then((data) => {
        notifys();
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        //this.setState({errorMessage: err.response.data.error.message});
      });
  };
  onSubmitAddress = async (e) => {
    e.preventDefault();
    const {
      address_country,
      address_address1,
      address_address2,
      address_city,
      address_full_name,
      address_landmark,
      address_mobile_number,
      address_pin_code,
      address_state,
    } = this.state;
    let dataToSend = {
      user_id: localStorage.getItem("user_id"),
      full_name: address_full_name,
      mobile_number: address_mobile_number,
      pin_code: address_pin_code,
      address1: address_address1,
      address2: address_address2,
      landmark: address_landmark,
      city: address_city,
      state: address_state,
      country: address_country,
    };
    console.log(dataToSend);
    API.Auth("user/update-address", dataToSend)
      .then((data) => {
        notifys();
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        //this.setState({errorMessage: err.response.data.error.message});
      });
  };
  onStateChange(event) {
    this.setState({ [event.target.name]: event.target.value });
    API.get(`common/getCities/${event.target.value}`).then((res) => {
      if (res) {
        this.setState({ cities: res });
      } else {
        this.setState({ cities: [] });
      }
    });
  }
  componentDidMount() {
    let userCheck = localStorage.getItem("name");
    if (!userCheck) {
      this.props.history.push(`/`);
      return;
    }
    API.get("common/getStates").then((res) => {
      this.setState({ states: res });
      console.log(res);
    });
    API.get(`order/customer/get/${localStorage.getItem("user_id")}`).then((data) => {
      this.setState({ orders: data });
    });
    API.get(`user/user-info/${localStorage.getItem("user_id")}`).then((data) => {
      console.log(data);
      this.setState({ userInfo: data });
      this.setState({ name: data.name });
      this.setState({ email: data.email });
      this.setState({ phone: data.mobile_number });
      if (data.address) {
        this.setState({ address_full_name: data.address.full_name });
        this.setState({ address_mobile_number: data.address.mobile_number });
        this.setState({ address_pin_code: data.address.pin_code });
        this.setState({ address_address1: data.address.address1 });
        this.setState({ address_address2: data.address.address2 });
        this.setState({ address_landmark: data.address.landmark });
        this.setState({ address_city: data.address.city });
        this.setState({ address_country: data.address.country });
        this.setState({ address_state: data.address.state });
      }
    });
  }

  render() {
    const {
      name,
      email,
      phone,
      address_address1,
      address_address2,
      address_city,
      address_full_name,
      address_landmark,
      address_mobile_number,
      address_country,
      address_pin_code,
      address_state,
    } = this.state;
    let stateList = this.state.states;
    let cityList = this.state.cities;
    let stateOptionTemplate = stateList.map((v) => <option value={v.state_id}>{v.name}</option>);
    let cityOptionTemplate = cityList.map((v) => <option value={v.city_id}>{v.name}</option>);
    return (
      <>
        <div id="account-page" className="section">
          <ToastContainer />
          {/* Container */}
          <div className="container">
            {/* Account Layout */}
            <div className="columns account-header">
              <div className="column main-column is-tablet-landscape-padded">
                {/* Title */}
                <div className="account-title">
                  <h2>ACCOUNT</h2>
                  <img className="brand-filigrane" src="assets/img/logo/nephos-greyscale.svg" alt="" />
                </div>
                {/* Account tabs */}
                <div className="tabs account-tabs">
                  <ul>
                    <li>
                      {" "}
                      <Link to="/user-profile">Account</Link>
                    </li>
                    <li>
                      {" "}
                      <Link to="/orders">Orders</Link>
                    </li>
                  </ul>
                </div>
                <div id="account-main-placeholder" className="main-placeholder is-hidden">
                  <div className="placeholder-content">
                    <img src="assets/img/illustrations/profile.svg" alt="" />
                    <h3>Welcome, Guest</h3>
                    <p>Guest users don't have accounts. Please login or register to view your account.</p>
                    <div className="button-wrap">
                      <a href="authentication.html" className="button big-button primary-button rounded raised">
                        Log In
                      </a>
                    </div>
                    <a>Don't have an account?</a>
                  </div>
                </div>
                {/* Account layout */}
                <div id="account-main" className="columns is-account-grid is-multiline">
                  {/*Loader*/}
                  <div className="account-loader is-active is-hidden">
                    <div className="loader is-pulled is-loading" />
                  </div>
                  {/* Details */}
                  <div className="column is-12">
                    {this.state.edit ? (
                      <div className="flat-card profile-info-card is-auto">
                        {/* Title */}
                        <form id="edit_profile" onSubmit={this.onSubmit}>
                          <div className="card-title ">
                            <h3>Contact info</h3>
                            {/* Save button */}
                            <div className="confirm-button is-vhidden">
                              <button type="submit" className="has-simple-popover">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-check feather-icons"
                                >
                                  <polyline points="20 6 9 17 4 12" />
                                </svg>
                              </button>
                            </div>
                          </div>
                          <div className="card-body">
                            <div className="columns">
                              <div className="column is-6">
                                <div className="field info-block has-flex-label has-validation">
                                  <label className="label-text">
                                    <span>Name</span>
                                  </label>
                                  <div className="control">
                                    <input
                                      id="edit-email"
                                      className="input is-default fake-email-validation"
                                      required
                                      name="name"
                                      value={name}
                                      onChange={this.onChange}
                                    />
                                  </div>
                                </div>
                                <div className="field info-block has-flex-label has-validation">
                                  <label className="label-text">
                                    <span>Email</span>
                                  </label>
                                  <div className="control">
                                    <input
                                      id="edit-email"
                                      className="input is-default fake-email-validation"
                                      required
                                      type="email"
                                      name="email"
                                      value={email}
                                      onChange={this.onChange}
                                    />
                                  </div>
                                </div>
                                <div className="field info-block has-flex-label has-validation">
                                  <label className="label-text">
                                    <span>Phone</span>
                                  </label>
                                  <div className="control">
                                    <input
                                      id="edit-email"
                                      className="input is-default fake-email-validation"
                                      name="phone"
                                      type="number"
                                      maxlength="12"
                                      value={phone}
                                      onChange={this.onChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    ) : null}

                    {this.state.edit ? null : (
                      <div className="flat-card profile-info-card is-auto">
                        {/* Title */}
                        <div className="card-title">
                          <h3>Account details</h3>
                          <div
                            className="edit-account has-simple-popover popover-hidden-mobile"
                            data-content="Edit Account"
                            data-placement="top"
                          >
                            <a
                              href="#"
                              onClick={() => {
                                this.EditProfile();
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-settings feather-icons"
                              >
                                <circle cx={12} cy={12} r={3} />
                                <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z" />
                              </svg>
                            </a>
                          </div>
                        </div>
                        {/* Contact Info */}
                        <div className="card-body">
                          <div className="columns">
                            <div className="column is-6">
                              <div className="info-block">
                                <span className="label-text">Name</span>
                                <span id="account-first-name" className="label-value">
                                  {this.state.userInfo.name}
                                </span>
                              </div>
                            </div>
                            <div className="column is-6">
                              <div className="info-block">
                                <span className="label-text">Email</span>
                                <span id="account-email" className="label-value">
                                  {this.state.userInfo.email}
                                </span>
                              </div>
                              <div className="info-block">
                                <span className="label-text">Phone</span>
                                <span id="account-phone-number" className="label-value">
                                  {this.state.userInfo.mobile_number}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Background Nephos Icon */}
                        <img className="card-bg" src="assets/img/logo/nephos-greyscale.svg" alt="" />
                      </div>
                    )}

                    {this.state.address_edit ? (
                      <div className="flat-card profile-info-card is-auto">
                        {/* Title */}
                        <form id="edit_profile" onSubmit={this.onSubmitAddress}>
                          <div className="card-title ">
                            <h3>Address info</h3>
                            {/* Save button */}
                            <div className="confirm-button is-vhidden">
                              <button type="submit" className="has-simple-popover">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-check feather-icons"
                                >
                                  <polyline points="20 6 9 17 4 12" />
                                </svg>
                              </button>
                            </div>
                          </div>
                          <div className="card-body">
                            <div className="columns">
                              <div className="column is-6">
                                <div className="field info-block has-flex-label has-validation">
                                  <label className="label-text">
                                    <span>Full Name</span>
                                  </label>
                                  <div className="control">
                                    <input
                                      id="edit-email"
                                      className="input is-default fake-email-validation"
                                      required
                                      name="address_full_name"
                                      value={address_full_name}
                                      onChange={this.onChange}
                                    />
                                  </div>
                                </div>
                                <div className="field info-block has-flex-label has-validation">
                                  <label className="label-text">
                                    <span>mobile</span>
                                  </label>
                                  <div className="control">
                                    <input
                                      id="edit-email"
                                      className="input is-default fake-email-validation"
                                      required
                                      type="number"
                                      name="address_mobile_number"
                                      value={address_mobile_number}
                                      onChange={this.onChange}
                                    />
                                  </div>
                                </div>
                                <div className="field info-block has-flex-label has-validation">
                                  <label className="label-text">
                                    <span>Address Line 1</span>
                                  </label>
                                  <div className="control">
                                    <input
                                      id="edit-email"
                                      className="input is-default fake-email-validation"
                                      name="address_address1"
                                      value={address_address1}
                                      onChange={this.onChange}
                                    />
                                  </div>
                                </div>
                                <div className="field info-block has-flex-label has-validation">
                                  <label className="label-text">
                                    <span>Address Line 2</span>
                                  </label>
                                  <div className="control">
                                    <input
                                      id="edit-email"
                                      className="input is-default fake-email-validation"
                                      name="address_address2"
                                      value={address_address2}
                                      onChange={this.onChange}
                                    />
                                  </div>
                                </div>
                                <div className="field info-block has-flex-label has-validation">
                                  <label className="label-text">
                                    <span>Landmark </span>
                                  </label>
                                  <div className="control">
                                    <input
                                      id="edit-email"
                                      className="input is-default fake-email-validation"
                                      name="address_landmark"
                                      value={address_landmark}
                                      onChange={this.onChange}
                                    />
                                  </div>
                                </div>
                                <div className="field info-block has-flex-label has-validation">
                                  <label className="label-text">
                                    <span>Pin Code </span>
                                  </label>
                                  <div className="control">
                                    <input
                                      id="edit-email"
                                      className="input is-default fake-email-validation"
                                      name="address_pin_code"
                                      value={address_pin_code}
                                      onChange={this.onChange}
                                    />
                                  </div>
                                </div>
                                <div className="field info-block has-flex-label has-validation">
                                  <label className="label-text">
                                    <span>State </span>
                                  </label>
                                  <div className="control">
                                    <select
                                      name="address_state"
                                      onChange={this.onStateChange}
                                      required
                                      className="input is-default fake-email-validation"
                                    >
                                      <option value="">---Select Satet ----</option>
                                      {stateOptionTemplate}
                                    </select>
                                  </div>
                                </div>
                                <div className="field info-block has-flex-label has-validation">
                                  <label className="label-text">
                                    <span>City </span>
                                  </label>
                                  <div className="control">
                                    <select
                                      name="address_city"
                                      onChange={this.onChange}
                                      required
                                      className="input is-default fake-email-validation"
                                    >
                                      <option value="">---Select City ----</option>
                                      {cityOptionTemplate}
                                    </select>
                                    {/* <input
                                      id="edit-email"
                                      className="input is-default fake-email-validation"
                                      name="address_city"
                                      value={address_city}
                                      onChange={this.onChange}
                                    /> */}
                                  </div>
                                </div>
                                {/* <div className="field info-block has-flex-label has-validation">
                                  <label className="label-text">
                                    <span>Country </span>
                                  </label>
                                  <div className="control">
                                    <input
                                      id="edit-email"
                                      className="input is-default fake-email-validation"
                                      name="address_country"
                                      value={address_country}
                                      onChange={this.onChange}
                                    />
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    ) : (
                      <div className="flat-card profile-info-card is-auto">
                        {/* Title */}
                        <form id="edit_profile">
                          <div className="card-title">
                            <h3>Address info</h3>
                            <div
                              className="edit-account has-simple-popover popover-hidden-mobile"
                              data-content="Edit Account"
                              data-placement="top"
                            >
                              <a
                                href="#"
                                onClick={() => {
                                  this.EditAddress();
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-settings feather-icons"
                                >
                                  <circle cx={12} cy={12} r={3} />
                                  <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z" />
                                </svg>
                              </a>
                            </div>
                          </div>
                          <div className="card-body">
                            <div className="columns">
                              <div className="column is-6">
                                <div className="field info-block has-flex-label has-validation">
                                  <label className="label-text">
                                    <span>Full Name</span>
                                  </label>
                                  <div className="control">
                                    <input
                                      id="edit-email"
                                      disabled
                                      className="input is-default fake-email-validation"
                                      required
                                      name="address_full_name"
                                      value={address_full_name}
                                      onChange={this.onChange}
                                    />
                                  </div>
                                </div>
                                <div className="field info-block has-flex-label has-validation">
                                  <label className="label-text">
                                    <span>mobile</span>
                                  </label>
                                  <div className="control">
                                    <input
                                      id="edit-email"
                                      disabled
                                      className="input is-default fake-email-validation"
                                      required
                                      type="number"
                                      name="address_mobile_number"
                                      value={address_mobile_number}
                                      onChange={this.onChange}
                                    />
                                  </div>
                                </div>
                                <div className="field info-block has-flex-label has-validation">
                                  <label className="label-text">
                                    <span>Address Line 1</span>
                                  </label>
                                  <div className="control">
                                    <input
                                      id="edit-email"
                                      disabled
                                      className="input is-default fake-email-validation"
                                      name="address_address1"
                                      value={address_address1}
                                      onChange={this.onChange}
                                    />
                                  </div>
                                </div>
                                <div className="field info-block has-flex-label has-validation">
                                  <label className="label-text">
                                    <span>Address Line 2</span>
                                  </label>
                                  <div className="control">
                                    <input
                                      id="edit-email"
                                      disabled
                                      className="input is-default fake-email-validation"
                                      name="address_address2"
                                      value={address_address2}
                                      onChange={this.onChange}
                                    />
                                  </div>
                                </div>
                                <div className="field info-block has-flex-label has-validation">
                                  <label className="label-text">
                                    <span>Landmark </span>
                                  </label>
                                  <div className="control">
                                    <input
                                      id="edit-email"
                                      disabled
                                      className="input is-default fake-email-validation"
                                      name="address_landmark"
                                      value={address_landmark}
                                      onChange={this.onChange}
                                    />
                                  </div>
                                </div>
                                <div className="field info-block has-flex-label has-validation">
                                  <label className="label-text">
                                    <span>Pin Code </span>
                                  </label>
                                  <div className="control">
                                    <input
                                      id="edit-email"
                                      disabled
                                      className="input is-default fake-email-validation"
                                      name="address_pin_code"
                                      value={address_pin_code}
                                      onChange={this.onChange}
                                    />
                                  </div>
                                </div>
                                {/* <div className="field info-block has-flex-label has-validation">
                                  <label className="label-text">
                                    <span>City </span>
                                  </label>
                                  <div className="control">
                                    <input
                                      id="edit-email"
                                      disabled
                                      className="input is-default fake-email-validation"
                                      name="address_city"
                                      value={address_city}
                                      onChange={this.onChange}
                                    />
                                  </div>
                                </div> */}
                                {/* <div className="field info-block has-flex-label has-validation">
                                  <label className="label-text">
                                    <span>State </span>
                                  </label>
                                  <div className="control">
                                    <input
                                      id="edit-email"
                                      disabled
                                      className="input is-default fake-email-validation"
                                      name="address_state"
                                      value={address_state}
                                      onChange={this.onChange}
                                    />
                                  </div>
                                </div> */}
                                {/* <div className="field info-block has-flex-label has-validation">
                                  <label className="label-text">
                                    <span>Country </span>
                                  </label>
                                  <div className="control">
                                    <input
                                      id="edit-email"
                                      disabled
                                      className="input is-default fake-email-validation"
                                      name="address_country"
                                      value={address_country}
                                      onChange={this.onChange}
                                    />
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div class="page-content bg-white">
                    <div class="section-full content-inner bg-white">
                        <div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="dlab-tabs  product-description tabs-site-button">
                                        <Tabs>
                                            <TabList className="nav nav-tabs ">
                                                <Tab selectedClassName="tab-active">
                                                    <Link to="#" >
                                                        <i class="fa fa-cog"></i>  Profile
                                                    </Link>
                                                </Tab>
                                                <Tab selectedClassName="tab-active">
                                                    <Link to="#" >
                                                        <i class="fa fa-cog"></i>  Addressee
                                                    </Link>
                                                </Tab>
                                                <Tab selectedClassName="tab-active">
                                                    <Link to="#">
                                                        <i class="fa fa-globe"></i> Orders
                                                    </Link>
                                                </Tab>
                                            </TabList>

                                            <TabPanel class="tab-pane ">
                                                <p class="m-b10">Suspendisse et justo. Praesent mattis commyolk augue Aliquam ornare hendrerit augue Cras tellus In pulvinar lectus a est Curabitur eget orci Cras laoreet. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse et justo. Praesent mattis  commyolk augue aliquam ornare augue.</p>
                                                <p>"But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences</p>
                                                <ul class="list-check primary">
                                                    <li>"But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and </li>
                                                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </li>
                                                </ul>
                                            </TabPanel>
                                            
                                            <TabPanel class="tab-pane ">
                                                 sdsd
                                            </TabPanel>

                                            <TabPanel class="tab-pane ">
                                                <Table hover className="table-hover-animation" responsive>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Order Total</th>
                                                            <th>Products In Order</th>
                                                            <th>Payment Mod</th>
                                                            <th>Order Date</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.orders.length === 0
                                                                ?
                                                                <tr>
                                                                    <th scope="row">Loading Orders...</th>
                                                                    <td>Loading Orders...</td>
                                                                    <td>Loading Orders...</td>
                                                                    <td>Loading Orders...</td>
                                                                    <td>Loading Orders...</td>
                                                                    <td>Loading Orders...</td>
                                                                </tr>
                                                                : this.state.orders.map(order => (
                                                                    <tr>
                                                                        <th scope="row">{order.order_id}</th>
                                                                        <td>{order.subtotal}</td>
                                                                        <td>{order.products.length}</td>
                                                                        <td>{order.payment.mode}</td>
                                                                        <td>{order.created_at}</td>
                                                                        <td>{order.status}</td>
                                                                    </tr>
                                                                ))
                                                        }

                                                    </tbody>
                                                </Table>
                                            </TabPanel>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
      </>
    );
  }
}
export default userPanel;
