import {
  ADD_ITEMS_TO_STATE,
  ADD_TO_CART,
  REMOVE_ITEM,
  SUB_QUANTITY,
  ADD_QUANTITY,
  ADD_SHIPPING,
} from "../actions/action-types/cart-actions";

const initState = {
  items: [],
  addedItems: [],
  total: 0,
};
const checkNullAndEmpty = (variable) => {
  return variable != null && variable != undefined && variable != "";
};
const cartReducer = (state = initState, action) => {
  if (action.type === ADD_ITEMS_TO_STATE) {
    state.items = action.data;
  }

  // //INSIDE HOME COMPONENT
  // if(action.type === ADD_TO_CART){
  //     console.log("action",action);
  //     return;
  //      let addedItem = state.items.find(item=> item.product_id === action.id)

  //       //check if the action product_id exists in the addedItems
  //      let existed_item= state.addedItems.find(item=> action.id === item.product_id)
  //     //  console.log("existed_item".existed_item)
  //     //  console.log("state.items",state.items)
  //     //  console.log("addedItem",addedItem)
  //     //  console.log("action",action)
  //      if(existed_item)
  //      {
  //         addedItem.quantity += 1
  //          return{
  //             ...state,
  //              total: state.total + addedItem.price
  //               }
  //     }
  //      else{
  //         addedItem.quantity = 1;
  //         //calculating the total
  //         let newTotal = state.total + addedItem.price
  //         console.log(state)
  //         return{
  //             ...state,
  //             addedItems: [...state.addedItems, addedItem],
  //             total : newTotal
  //         }

  //     }
  // }

  if (action.type === ADD_TO_CART) {
    console.log("action", action);
    let productData = action.data;
    let cartData = {
      products: [],
      total: 0,
    };
    let currentCart = localStorage.getItem("cart");
    currentCart = JSON.parse(currentCart);
    if (checkNullAndEmpty(currentCart)) {
      console.log("1");
      let existed_item = currentCart.products.find((item) => action.id === item.product_id);
      if (existed_item) {
        for (var i = 0; i < currentCart.products.length; i++) {
          if (currentCart.products[i].product_id === action.id) {
            console.log(currentCart.products[i].quantity);
            currentCart.products[i].quantity = currentCart.products[i].quantity + 1;
            console.log(currentCart.products[i].quantity);
          }
        }
        currentCart.total += productData.price;
        localStorage.setItem("cart", JSON.stringify(currentCart));
      } else {
        productData.quantity = 1;
        currentCart.products.push(productData);
        currentCart.total += productData.price;
        localStorage.setItem("cart", JSON.stringify(currentCart));
      }
    } else {
      console.log("2");
      productData.quantity = 1;
      cartData.products.push(productData);
      cartData.total += productData.price;
      localStorage.setItem("cart", JSON.stringify(cartData));
    }
    return true;
  }
  if (action.type === REMOVE_ITEM) {
    let productData = action.data;
    let currentCart = localStorage.getItem("cart");
    currentCart = JSON.parse(currentCart);

    let existed_item = currentCart.products.find((item) => action.id === item.product_id);
    let new_items = currentCart.products.filter((item) => action.id !== item.product_id);
    console.log("existed_item", existed_item);
    console.log("new_items", new_items);
    console.log("currentCart", currentCart);
    if (existed_item) {
      currentCart.products = new_items;
      localStorage.setItem("cart", JSON.stringify(currentCart));
    }

    // let itemToRemove= state.addedItems.find(item=> action.id === item.product_id)
    // let new_items = state.addedItems.filter(item=> action.id !== item.product_id)

    // //calculating the total
    // let newTotal = state.total - (itemToRemove.price * itemToRemove.quantity )
    // console.log(itemToRemove)
    // return{
    //     ...state,
    //     addedItems: new_items,
    //     total: newTotal
    // }
  }
  //INSIDE CART COMPONENT
  if (action.type === ADD_QUANTITY) {
    let productData = action.data;
    let currentCart = localStorage.getItem("cart");
    currentCart = JSON.parse(currentCart);

    let existed_item = currentCart.products.find((item) => action.id === item.product_id);
    if (existed_item) {
      for (var i = 0; i < currentCart.products.length; i++) {
        if (currentCart.products[i].product_id === action.id) {
          console.log(currentCart.products[i].quantity);
          currentCart.products[i].quantity = currentCart.products[i].quantity + 1;
          console.log(currentCart.products[i].quantity);
        }
      }
      currentCart.total += productData.price;
      localStorage.setItem("cart", JSON.stringify(currentCart));
    }

    // let addedItem = state.items.find(item=> item.product_id === action.id)
    //   addedItem.quantity += 1
    //   let newTotal = state.total + addedItem.price
    //   return{
    //       ...state,
    //       total: newTotal
    //   }
  }
  if (action.type === SUB_QUANTITY) {
    let productData = action.data;
    let currentCart = localStorage.getItem("cart");
    currentCart = JSON.parse(currentCart);

    let existed_item = currentCart.products.find((item) => action.id === item.product_id);
    if (existed_item) {
      if (existed_item.quantity !== 1) {
        for (var i = 0; i < currentCart.products.length; i++) {
          if (currentCart.products[i].product_id === action.id) {
            console.log(currentCart.products[i].quantity);
            currentCart.products[i].quantity = currentCart.products[i].quantity - 1;
            console.log(currentCart.products[i].quantity);
          }
        }
        currentCart.total += productData.price;
        localStorage.setItem("cart", JSON.stringify(currentCart));
      }
    }

    // let addedItem = state.items.find(item=> item.product_id === action.id)
    // //if the qt == 0 then it should be removed
    // console.log(addedItem)
    // if(addedItem.quantity === 1){
    //     // let new_items = state.addedItems.filter(item=>item.product_id !== action.id)
    //     // let newTotal = state.total - addedItem.price
    //     // return{
    //     //     ...state,
    //     //     addedItems: new_items,
    //     //     total: newTotal
    //     // }
    // }
    // else {
    //     addedItem.quantity -= 1
    //     let newTotal = state.total - addedItem.price
    //     return{
    //         ...state,
    //         total: newTotal
    //     }
    // }
  }

  if (action.type === ADD_SHIPPING) {
    return {
      ...state,
      total: state.total + 6,
    };
  }

  if (action.type === "SUB_SHIPPING") {
    return {
      ...state,
      total: state.total - 6,
    };
  } else {
    return state;
  }
};

export default cartReducer;
