import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
//New Imports
import Header from "./layout/header";
import Footer from "./layout/footer";
import Index from "./pages";
import PrimaryProduct from "./pages/primary-product";
import Gallery from "./pages/gallery";
import Contact from "./pages/contact";
import About from "./pages/about";
import Shop from "./pages/shop";
import Login from "./pages/login";
import ProductDetails from "./pages/product-details";
import Cart from "./pages/cart";
import Checkout from "./pages/checkout";
import Register from "./pages/register";
import userPanel from "../markup/pages/user";
import userPanelOrder from "../markup/pages/user/orders";
import userPanelOrderDetails from "../markup/pages/user/orderDetails";
import PrivacyPolicy from "../markup/pages/privacy-policy";
import TermsConditions from "../markup/pages/terms-and-conditions";
import RefundPolicy from "../markup/pages/refund-policy";
import ForgotPassword from "../markup/pages/forgot-password";

class Router extends Component {
  render() {
    return (
      <BrowserRouter basename={""}>
        <Header />
        <div className="page-wraper">
          <Switch>
            {/* New Routes */}
            <Route path="/" exact component={Index} />
            <Route path="/about-us" exact component={About} />
            <Route path="/contact-us" exact component={Contact} />
            <Route path="/login" exact component={Login} />
            <Route path="/register" exact component={Register} />
            <Route path="/category/:catslug" component={Shop} />
            <Route path="/product/:productId" component={ProductDetails} />
            <Route path="/cart" component={Cart} />
            <Route path="/checkout" component={Checkout} />
            <Route path="/user-profile" component={userPanel} />
            <Route path="/orders" component={userPanelOrder} />
            <Route path="/order/:orderId" component={userPanelOrderDetails} />
            <Route path="/primary-product" component={PrimaryProduct} />
            <Route path="/gallery" component={Gallery} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/terms-and-conditions" component={TermsConditions} />
            <Route path="/refund-policy" component={RefundPolicy} />
            <Route path="/forgot-password" component={ForgotPassword} />
          </Switch>
        </div>
        <Footer />
      </BrowserRouter>
    );
  }
}

export default Router;
