import React, { Component } from "react";
import { Link } from "react-router-dom";
import API from "../../Api";
class Login extends Component {
  componentDidMount() {
    if (localStorage.getItem("name")) {
      this.props.history.push(`/user-profile`);
    }
    // let login =  API.userAuth(`user/login`,{email:'email',password:'password'});
  }

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errorMessage: "",
    };
  }
  onChange = (e) => {
    /*
          Because we named the inputs to match their
          corresponding values in state, it's
          super easy to update the state
        */
    this.setState({ [e.target.name]: e.target.value });
  };
  onSubmit = async (e) => {
    e.preventDefault();

    const { email, password } = this.state;
    API.Auth("user/login", { email: email, password: password })
      .then((data) => {
        localStorage.setItem("user_id", data.user_id);
        localStorage.setItem("token", data.token);
        localStorage.setItem("name", data.name);
        this.props.history.push("/");
      })
      .catch((err) => {
        console.log(err);
        this.setState({ errorMessage: err.response.data.error.message });
      });

    //let login = await API.userAuth(`user/login`,{email:email,password:password});
    //console.log(login)
    // API.userAuth(`user/login`,{email:email,password:password}).then(res=>{
    //     console.log(res.data.payload);
    //     localStorage.setItem('user_id', JSON.stringify(res.data.payload.user_id));
    //     localStorage.setItem('token', JSON.stringify(res.data.payload.token));
    //     localStorage.setItem('name', JSON.stringify(res.data.payload.name));
    // }).catch(err => {
    //     this.setState({errorMessage: err.message});
    // });
    // return false;
  };

  render() {
    const { email, password } = this.state;
    return (
      <>
        <div className="page-content bg-white">
          <div className="section-full content-inner shop-account">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <h2 className="font-weight-700 m-t0 m-b40">ALREADY REGISTERED?</h2>
                </div>
              </div>
              <div className="row dzseth">
                <div className="col-lg-6 col-md-6 m-b30">
                  <div className="p-a30 border-1 seth">
                    <div className="tab-content">
                      <h4 className="font-weight-700">NEW CUSTOMER</h4>
                      <p className="font-weight-600">
                        By creating an account with our store, you will be able to move through the checkout process faster, store multiple
                        shipping addresses, view and track your orders in your account and more.
                      </p>
                      <Link className="site-button m-r5 button-lg radius-no" to="/register">
                        CREATE AN ACCOUNT
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 m-b30">
                  <div className="p-a30 border-1 seth">
                    <div className="tab-content nav">
                      <form id="login" className="tab-pane active col-12 p-a0 " onSubmit={this.onSubmit}>
                        <h4 className="font-weight-700">LOGIN</h4>
                        <p className="font-weight-600">If you have an account with us, please log in.</p>
                        <div className="form-group">
                          <label className="font-weight-700">E-MAIL *</label>
                          <input name="email" required className="form-control" type="email" value={email} onChange={this.onChange} />
                        </div>
                        <div className="form-group">
                          <label className="font-weight-700">PASSWORD *</label>
                          <input
                            name="password"
                            required
                            className="form-control "
                            type="password"
                            value={password}
                            onChange={this.onChange}
                          />
                        </div>
                        <Link to="/forgot-password">
                          <div className="form-group">
                            <span> Forgot Password ? </span>
                          </div>
                        </Link>
                        <div className="form-group">
                          {this.state.errorMessage && <span className="error"> Please Check Email or Password! </span>}
                        </div>
                        <br />
                        <div className="text-left">
                          <button type="submit" className="site-button m-r5 button-lg radius-no">
                            login
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Login;
